import { spacing, size, fontStyles } from './';

const colors = {
  black: {
    default: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.60)',
    tertiary: 'rgba(0, 0, 0, 0.38)',
    soft: '#C2C2C2',
  },

  white: {
    default: '#FFFFFF',
    secondary: 'rgba(255,255, 255, 0.6)',
  },
};

export const color = (name, shade = 'default') => {
  return colors[name] && colors[name][shade];
};

export const theme = {
  text: color('black'),
  textLight: color('black', 'secondary'),
  textLighter: color('black', 'tertiary'),
  textSoft: color('black', 'soft'),

  separator: 'rgba(0,0,0, 0.24)',
  separatorSoft: 'rgba(0, 0, 0, 0.12)',
  green: '#73c992',

  textDisabled: 'rgba(0,0,0, 0.24)',
  disabled: '#eeeeee',

  backgroundLight: '#F9F9F9',

  facebook: '#4568B2',

  primary: '#73c992',
  primaryLight: '#7AD69C',
  secondary: '#FFA24B',

  tagCategrories: 'rgb(116, 75, 148)',
  tagCategroriesLight: 'rgba(116, 75, 148, 0.12)',

  tagStyles: 'rgb(255, 162, 75)',
  tagStylesLight: 'rgba(255, 162, 75, 0.12)',

  tagTypes: 'rgb(115, 201, 146)',
  tagTypeslight: 'rgba(115, 201, 146, 0.12)',

  softWhite: color('white', 'secondary'),
  white: color('white', 'default'),
  error: '#D11E1E',
  errorLight: '#FF0601',
  errorLighter: '#FFF1F0',

  footerBg: '#67CB8B',

  spacing,
  size,
  fontStyles,
};
