export const LANGUAGES = ['en', 'fr'];
export const DEFAULT_LANGUAGE = 'fr';
export const SITE_URL = process.env.GATSBY_SITE_URL;
export const API_URL = process.env.GATSBY_API_URL;
export const QUANTCAST_ID = process.env.GATSBY_QUANTCAST_ID;
export const QUANTCAST_HOST = process.env.GATSBY_QUANTCAST_HOST;
export const GOOGLE_API_KEY = process.env.GATSBY_GOOGLE_API_KEY;
export const GOOGLE_APPLICATION_CREDENTIALS =
  process.env.GOOGLE_APPLICATION_CREDENTIALS;
export const GCS_BUCKET = process.env.GCS_BUCKET;
export const NODE_ENV = process.env.NODE_ENV;
export const FB_DOMAIN_VERIFICATION = process.env.GATSBY_FB_DOMAIN_VERIFICATION;
