import React from 'react';

const IcFacebook = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Groupe_33" data-name="Groupe 33" transform="translate(-1192 -3930)">
      <g
        id="Rectangle_23"
        data-name="Rectangle 23"
        transform="translate(1192 3930)"
        fill="#fff"
        stroke="#707070"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      </g>
      <path
        id="Icon_awesome-facebook-f"
        data-name="Icon awesome-facebook-f"
        d="M12.763,12.536,13.382,8.5H9.512V5.885a2.017,2.017,0,0,1,2.274-2.179h1.759V.272A21.455,21.455,0,0,0,10.422,0c-3.187,0-5.27,1.932-5.27,5.429V8.5H1.609v4.033H5.152v9.75h4.36v-9.75Z"
        transform="translate(1196.423 3930.857)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default IcFacebook;
