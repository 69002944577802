import React from 'react';
import styled, { css } from 'styled-components';
import Downshift from 'downshift';
import { Field } from '../ui/forms';
import { theme } from '../ui/colors';

import {
  PopoverContainer,
  PopoverMenu,
  PopoverItem,
  PopoverItemButton,
} from 'ui/popover';
import { MdExpandMore, MdPerson, MdSearch } from 'react-icons/md';
import find from 'lodash/find';
import { Input } from 'ui/forms';
import {
  Wrapper,
  Trigger,
  PlaceHolderLabel,
  ValueWrapper,
  Icon,
} from 'ui/select';

const Label = styled.div`
  color: ${({ theme }) => theme.textLighter};
  text-align: left;
`;

const FlexInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.textLight} !important;
  }

  svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }

  ${props =>
    props.variant === 'search' &&
    css`
      border: 1px solid ${({ theme }) => theme.separator};
      border-radius: 4px;
    `}

  ${props =>
    props.variant === 'hotel' &&
    css`
      border: 1px solid ${({ theme }) => theme.separator};
      border-radius: 4px;
    `}
    ${props =>
      props.variant==='article' &&
      css` 
       input{
         height:60px !important;
       }
     `}
  
`;

const logoType = type => {
  switch (type) {
    case 'profile':
      return <MdPerson color="#73c992" size={20} />;
    case 'guests':
      return <MdExpandMore color="#73c992" size={20} />;
    case 'location':
      return <MdExpandMore color="#73c992" size={20} />;
    case 'article':
      return <MdExpandMore color="#FFA24B" size={20} />;
    default:
      return <MdExpandMore color="#73c992" size={20} />;
  }
};

export const Select = ({
  options,
  value,
  onChange,
  size,
  placeholder,
  variant,
  style,
  icon,
  label,
  type,
  hasInput,
  full,
  hasIcon,
  ...props
}) => (
  <Wrapper variant={variant}>
    <Downshift
      placeholder={placeholder}
      itemToString={item => (item ? item.label : '')}
      onSelect={onChange}
      selectedItem={find(options, { value })}
      {...props}
    >
      {({
        getItemProps,
        getInputProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        selectedItem,
        getToggleButtonProps,
      }) => (
        <div style={{ height: '100%' }}>
          {hasInput ? (
            <FlexInput variant={variant}>
              <Input
                variant={variant}
                full={full}
                {...getInputProps()}
                placeholder={placeholder}
              />
              {hasIcon ? <MdSearch color="#73c992" size={24} /> : null}
            </FlexInput>
          ) : (
            <>
              {label ? <Label>{label}</Label> : null}
              <Trigger
                style={{ height: '100%' }}
                {...getToggleButtonProps()}
                variant={variant}
                  size={size}
                icon={icon}
              >
                <PlaceHolderLabel variant={variant}>
                  {(selectedItem && selectedItem.value && (
                    <ValueWrapper variant={variant}>
                      <span>{selectedItem.label}</span>
                      <img src={selectedItem.icon} alt="" />
                    </ValueWrapper>
                  )) ||
                    placeholder}
                </PlaceHolderLabel>
                {type!=='none' && (
                  <Icon >{logoType(type)}</Icon>
                )}
              </Trigger>
            </>
          )}

          {isOpen && (
            <PopoverContainer
              variant={variant}
              shouldBeVisible={props.isVertical}
            >
              <PopoverMenu variant={variant} {...getMenuProps()}>
                {(options || []).map((item, index) => (
                  <PopoverItem
                    variant={variant}
                    isSelected={
                      (selectedItem && selectedItem.value) === item.value
                    }
                    isActive={highlightedIndex === index}
                    {...getItemProps({
                      key: index,
                      index,
                      item,
                      style: {
                        color:
                          highlightedIndex === index ? theme.primary : 'black',
                      },
                    })}
                  >
                    <PopoverItemButton
                      variant={variant}
                      onClick={e => e.preventDefault()}
                    >
                      {item.label}
                      {item?.icon && <img src={item.icon} alt="" />}
                    </PopoverItemButton>
                  </PopoverItem>
                ))}
              </PopoverMenu>
            </PopoverContainer>
          )}
        </div>
      )}
    </Downshift>
  </Wrapper>
);

const SelectField = props => (
  <Field {...props}>
    {({ field, form: { setFieldValue } }) => (
      <Select
        {...props}
        {...field}
        onChange={({ value }) => {
          setFieldValue(field.name, value);
        }}
      />
    )}
  </Field>
);

export default SelectField;
