import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { List, ButtonReset } from '../ui';
import { FormLabel } from '../ui/forms';

const Wrapper = styled(List)`
  display: flex;
`;

const ItemButton = styled(ButtonReset)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  transition: background-color 100ms ease, color 100ms ease;
  &:hover {
    background-color: ${({ theme }) => theme.primaryLighten};
    color: rgba(0, 0, 0, 0.87);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &,
      &:hover {
        background-color: #67CB8B;
        color: #fff;
      }
    `}
`;

const Item = styled.li`
  box-shadow: inset 0 0 0 1px #e0e0e0;
  overflow: hidden;
  border-radius: 10px;

  + li {
    margin-left: 12px;
  }
`;

const SegmentedControl = ({ options, onChange, values, label, disabled }) => {
  const [selectedValue, setSelectedValue] = useState(values || []);

  const handleToggleValue = value => {
    setSelectedValue(value);
    if (Boolean(onChange)) {
      onChange(value);
    }
  };

  return (
    <>
      {Boolean(label) && <FormLabel>{label}</FormLabel>}
      <Wrapper>
        {options.map(({ label, value }, index) => (
          <Item key={index}>
            <ItemButton
              isActive={selectedValue === value}
              onClick={() => handleToggleValue(value)}
              type="button"
              disabled={disabled}
            >
              {label}
            </ItemButton>
          </Item>
        ))}
      </Wrapper>
    </>
  );
};

SegmentedControl.defaultProps = { multiple: false, values: [] };

export default SegmentedControl;
