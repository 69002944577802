import styled, { css } from 'styled-components';
import { Link } from '@reach/router';
import { List, ButtonReset, media } from 'ui';

export const PopoverContainer = styled.div`
position: absolute;
z-index: ${({ shouldBeVisible }) => (shouldBeVisible ? 315 : 10)};
top: calc(100% + ${({ theme }) => theme.spacing(0.5)});
background-color: transparent;
border: 1px solid rgba(0, 0, 0, 0.24);
box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
border: 0;
border-radius: 20px;
overflow: auto;
min-width: 100%;
max-height: 300px;

  ${props =>
    props.showOverflow &&
    css`
      max-height: 800px;
      overflow: show;
    `}

  ${props =>
    props.width === 'auto' &&
    css`
      width: auto;
    `}

  ${props =>
    props.variant === 'natural' &&
    css`
      top: calc(100% + ${({ theme }) => theme.spacing(0.25)});
      right: 0;
      background-color: #fff;
      box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.1);
      border: 0;
      border-top: 4px solid ${({ theme }) => theme.primary};
      border-radius: 0;
    `}

    ${props =>
      props.alignment === 'right' &&
      css`
        right: 0;
      `}

    ${props =>
      props.alignment === 'left' &&
      css`
        left: 0;
      `}

      ${props =>
        props.alignment === 'center' &&
        css`
          left: -130px;
        `}
    
    ${props =>
      props.inHeader &&
      css`
        top: 100%;

        ${media.tablet`
          top: calc(100% + ${({ theme }) => theme.spacing(1.5)});
          max-width: max(100%, 420px);
        `}

        ${media.desktop`
          width: max(100%, 460px);
        `}
      `}
      ${props =>
        props.rangeSlider &&
        css`
          top: 100%;

          ${media.tablet`
          left:0px;
          margin-top: 10px;
          width: 500px;
          `}
          ${media.mobile`
          left:0px;
          margin-top: 10px;
          min-width: initial;
          max-width: 500px;
          width: 90%;
          margin-left: 5%;
          `}
        `}
      ${props =>
        props.variant === 'article' &&
        css`
         min-width:350px;
        `}
`;
export const PopoverMenu = styled(List)`
  padding: 30px;
  font-size: 1.1rem;
  background-color: #fff;
  overflow: auto;
  ${props =>
    props.variant === 'menuburger' &&
    css`
      height: 75vh;
      width: 100vw;

      @media (max-width: 400px) {
        height: 80vh;
      }
    `};
  ${props =>
    props.variant === 'natural' &&
    css`
      background-color: rbga(0, 0, 0, 0.7);
    `};

  ${props =>
    props.variant === 'menuburger' &&
    css`
      padding: 35px !important;
      margin-top: 50px;
    `};
`;

export const PopoverContent = styled.div`
  padding: 0;
`;

export const PopoverItemButton = styled(ButtonReset)`
  ${({ theme }) => theme.fontStyles.bodyLarge};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  text-align: inherit;
  padding: ${({ theme }) => theme.spacing(0.75)};
  color: inherit !important;

  &:hover {
    color: ${({ theme }) => theme.primary} !important;
  }

  ${props =>
    props.variant === 'natural' &&
    css`
      background-color: rbga(0, 0, 0, 0.7);
      border: 0;
      border-radius: 0;
      color: ${({ theme }) => theme.textLighter};
    `}
    
  ${props =>
    props.inHeader &&
    css`
      padding: ${({ theme }) => theme.spacing()} 0;
      color: ${({ theme }) => theme.textLighter};
      font-size: 16px;
      font-weight: 400;

      &:hover {
        color: ${({ theme }) => theme.text};
      }
    `}

  ${props =>
    props.isResponsiveMenu &&
    css`
      color: ${({ theme }) => theme.textLighter};
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      left: 0;

      &:hover {
        color: ${({ theme }) => theme.text};
      }
    `}
`;

export const PopoverItem = styled.li`
  position: relative;
  color: black;
  button {
    text-align: center;
  }

  ${props =>
    props.isBorderTop &&
    css`
      margin-left: ${({ theme }) => theme.spacing(1)};
      margin-top: ${({ theme }) => theme.spacing(2)};
    `}

  ${props =>
    props.isResponsiveMenu &&
    css`
      button {
        text-align: left;
      }
    `}
`;

export const NaturalPopoverItem = styled.li`
  position: relative;
  color: ${({ theme }) => theme.text};
  font-size: 20px;

  + li:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: '';
    display: block;
  }

  button {
    width: 100%;
    text-align: left;
  }

  ${media.desktop`
    white-space: nowrap;
  `}

  ${props =>
    props.isActive &&
    css`
      ${PopoverItemButton} {
        color: ${({ theme }) => theme.primary};

        ${props =>
          props.variant === 'reverse' &&
          css`
            color: ${({ theme }) => theme.text};
          `}
      }
    `}

  ${props =>
    props.isSelected &&
    css`
      ${PopoverItemButton} {
        color: ${({ theme }) => theme.primary};

        ${props =>
          props.variant === 'reverse' &&
          css`
            color: ${({ theme }) => theme.text};
          `}
      }
    `}
`;

export const PopoverItemLink = PopoverItemButton.withComponent(Link);

export const PopoverChunk = styled.div`
  padding: 0;
  position: relative;

  ~ *:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top: 1px solid black;
  }
`;

export const PopoverChunkHeader = styled.div`
  color: black;
  padding: 0;
`;

export const PopoverTitle = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 17px;
`;

export const PopoverDescription = styled.p`
  margin: 0;
  font-size: 14px;
  color: black;
`;

export const PopoverItemMainContent = styled.div`
  font-weight: 500;
`;

export const PopoverItemMeta = styled.div`
  font-size: 14px;
  color: black;
`;
