import React from 'react';

const IcLinkedIn = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Groupe_34" data-name="Groupe 34" transform="translate(-1241 -3930)">
      <g
        id="Rectangle_24"
        data-name="Rectangle 24"
        transform="translate(1241 3930)"
        fill="#fff"
        stroke="#707070"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      </g>
      <path
        id="Icon_awesome-linkedin-in"
        data-name="Icon awesome-linkedin-in"
        d="M4.7,21H.347V6.98H4.7ZM2.521,5.067A2.533,2.533,0,1,1,5.043,2.522,2.543,2.543,0,0,1,2.521,5.067ZM21,21H16.651V14.175c0-1.627-.033-3.713-2.264-3.713-2.264,0-2.61,1.767-2.61,3.6V21H7.428V6.98H11.6V8.892h.061a4.575,4.575,0,0,1,4.119-2.264C20.19,6.628,21,9.53,21,13.3V21Z"
        transform="translate(1242.573 3931.5)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default IcLinkedIn;
