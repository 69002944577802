import React from 'react';
import { Formik } from 'formik';
import Button from '../components/UI/Button';
import { useTranslation } from 'react-i18next';
import Input from './UI/Input';

export default function FooterNewsletter() {
  const { t } = useTranslation();

  return (
    <>
      <div className="h-px w-full bg-gray-100"/>

      <div className="tw bg-white py-28">
        <Formik
          onSubmit={() => {}}
          initialValues={{
            email: ''
          }}
        >
          {({ isSubmitting }) => (
            <form
              method="post"
              action="https://feelingo-lm.mail-eur.net/account"
            >
              <div className="max-w-lg mx-auto px-4">
                <div className="mb-12 text-center text-4xl font-bold">
                  {t('newFooter.newsletter.title')}
                </div>

                <div className="flex justify-between items-center space-x-2">
                  <Input
                    label={t('newFooter.newsletter.email')}
                    name="email"
                    type="email"
                    placeholder={t('newFooter.newsletter.emailPlaceholder')}
                    childClass="w-full"
                    required
                  />

                  <input type="hidden" name="join" value="777029" />

                  <Button
                    paddingClass="py-3 px-6"
                    childClass="w-32 text-sm"
                    type="submit"
                    loading={isSubmitting}
                  >
                    {t('newFooter.newsletter.button')}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  )
}
