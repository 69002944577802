import React from 'react';
import { Field, ErrorMessage } from 'formik';
import styled , {css} from 'styled-components';

const FieldContent = styled.div`
    ${props =>
      props.isProfile &&
      css`
      > input {
      &:focus,
      &:active {
        border: 2px solid rgb(243 244 246) !important;
        border-radius : 10px;
        padding: 15px 5px 15px 8px;
      }
    }
    `}
`;

function Input(props) {
  return (
    <div className={props.childClass}>
      <label className="block text-xs font-bold text-brand-black uppercase">
        {props.label}
        {props.required && (
          <span className="ml-0.5 text-red-500">*</span>
        )}
      </label>
      <FieldContent className="mt-1" isProfile={props.isProfile}>
        <Field
          className={`form-input block w-full px-0 border-0 border-b-2 border-gray-100 focus:border-brand-orange focus:ring-0 ${props.fieldClass}`}
          name={props.name}
          type={props.type ?? 'text'}
          placeholder={props.placeholder ?? (props.autoplaceholder && `Entrez votre ${props.label.toLowerCase()}`)}
          {...props}
        >
          {props.children}
        </Field>
        <ErrorMessage
          name={props.name}
          className="mt-1 text-red-600"
          component="div"
        />
      </FieldContent>
    </div>
  )
}

export default Input;
