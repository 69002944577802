import React, { useState } from 'react';
import UtilModal, { CONTACT_US } from 'components/UtilModal';
import Modal from 'components/Modal';
import UtilsContext from 'contexts/util';

const UtilsProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState();
  const [kind, setkind] = useState(CONTACT_US);
  const [modalOptions, setModalOptions] = useState({});

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleUtilKindChange = (kind, modalOptions) => {
    setModalOptions(modalOptions);
    setIsOpen(true);
    setkind(kind);
  };

  return (
    <UtilsContext.Provider
      value={{
        onUtilKindChange: handleUtilKindChange,
        onClose: handleCloseModal,
      }}
    >
      <>
        {isOpen && (
          <Modal
            isOpen
            onRequestClose={handleCloseModal}
            scrollable
            {...modalOptions}
          >
            <UtilModal kind={kind} />
          </Modal>
        )}
        {children}
      </>
    </UtilsContext.Provider>
  );
};

export default UtilsProvider;
