import React from 'react';
import PageContext from 'contexts/page';
import { getLanguagePrefix } from 'utils';

const PageProvider = ({ children, pageContext }) => {
  const { language } = pageContext;

  return (
    <PageContext.Provider
      value={{
        language,
        getLanguagePrefix: url => getLanguagePrefix(language, url),
        ...pageContext,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
