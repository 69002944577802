import React from 'react';
import styled, { css } from 'styled-components';
import BedIcon from '../assets/images/BedIcon';

import { Button, media } from '../ui';
import { useTranslation } from 'react-i18next';

export const TitleContainer = styled.div`
  padding: 0 ${({ theme }) => theme.spacing()} ${({ theme }) =>
  theme.spacing()} ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.text};
  ${({ theme }) => theme.fontStyles.h2};
  font-weight: 400;

  ${props =>
    props.reverse &&
    css`
      color: #fff;
      text-shadow: 0 0 13px rgba(0, 0, 0, 0.6);
    `}
  ${props =>
    props.noPaddingLeft &&
    css`
      padding-left:0px !important;
    `}

  ${props =>
    props.bold &&
    css`
      font-weight: 700 !important;
    `}

  ${props =>
    props.small &&
    css`
      ${({ theme }) => theme.fontStyles.bodyLarge};
    `}
    
  ${props =>
    props.homeTitle &&
    css`
       font-size:40px !important;
    `}

    ${props =>
      props.demarche &&
      css`
        font-weight: 700;
        max-width : 550px;
        margin: auto;
      `}
  ${props =>
    props.carnet &&
    css`
      font-weight: 700;
      margin-left: auto;
      padding: 0;

    `}
    ${props =>
      props.resultPage &&
      css`
        font-weight: bold;
        margin-left: auto;  
      `}
`;

export const GridContainer = styled.div`
  display: grid;

  > * {
    padding-top: 0;
  }

  ${props =>
    props.noLeftMargin &&
    css`
      margin-left: 0 !important;
    `}

  ${props =>
    props.hasTopMargin &&
    css`
      margin-top: ${({ theme }) => theme.spacing(3)};
    `}

  ${props =>
    props.baseline &&
    css`
      align-items: baseline;
    `}

  ${props =>
    props.variant === 'split' &&
    css`
      grid-column-gap: ${({ theme }) => theme.spacing(3)};
      grid-template-columns: 1fr 300px;
      margin-top: 0;
      width: 1200px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    `}

    ${props =>
      props.variant === 'splitReverse' &&
      css`
        grid-column-gap: ${({ theme }) => theme.spacing()};
        grid-template-columns: 200px 650px;
        margin-top: 0;
        width: 1200px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      `}

  ${props =>
    props.variant === 'explore' &&
    css`
      grid-column-gap: ${({ theme }) => theme.spacing(2)};
      grid-row-gap: ${({ theme }) => theme.spacing(3)};

      ${media.tablet`
        grid-template-columns: repeat(2, 1fr);
      `}

      ${media.medium`
        grid-template-columns: repeat(3, 1fr);
      `}
    `}

    ${props =>
      props.variant === 'certification' &&
      css`
        grid-column-gap: ${({ theme }) => theme.spacing(2)};
        grid-row-gap: ${({ theme }) => theme.spacing(3)};
  
        ${media.tablet`
          grid-template-columns: repeat(2, 1fr);
        `}
  
        ${media.medium`
          grid-template-columns: repeat(4, 1fr);
        `}
      `}

      ${props =>
        props.variant === 'buketList' &&
        css`
          grid-column-gap: ${({ theme }) => theme.spacing(2)};
          grid-row-gap: ${({ theme }) => theme.spacing(3)};
    
          ${media.tablet`
            grid-template-columns: repeat(2, 1fr);
          `}
    
          ${media.medium`
            grid-template-columns: repeat(2, 1fr);
          `}
        `}

    ${props =>
      props.variant === 'about' &&
      css`
        grid-column-gap: ${({ theme }) => theme.spacing(2)};
        grid-template-columns: minmax(200px, 1fr);
        grid-row-gap: ${({ theme }) => theme.spacing(3)};

        ${media.tablet`
          grid-template-columns: repeat(2, 1fr);
        `}

        ${media.medium`
          grid-template-columns: repeat(4, 1fr);
        `}
      `}

  ${props =>
    props.variant === 'services' &&
    css`
      max-width: 100%;
      grid-template-columns: minmax(200px, 1fr);
      grid-column-gap: ${({ theme }) => theme.spacing(2)};
      grid-row-gap: ${({ theme }) => theme.spacing()};

      ${media.tablet`
        grid-template-columns: repeat(1, 1fr);
      `}

      ${media.medium`
        grid-template-columns: repeat(2, 1fr);
      `}

      ${media.desktop`
      grid-template-columns: repeat(3, 1fr);
    `}
    `}
    
    ${props =>
    props.variant === 'sustainableEngagements' &&
    css`
        margin-top:50px;
        width: 100%;
        grid-template-columns: 1fr;
        grid-column-gap: ${({ theme }) => theme.spacing(4)};
        grid-row-gap: 0px;
  
        ${media.tablet`
          grid-template-columns: repeat(2, 1fr);
        `}
  
        ${media.desktop`
          grid-template-columns: repeat(3, 1fr);
        `}
    `}

    ${props =>
      props.variant === 'sustainable' &&
      css`
        grid-template-columns: minmax(200px, 1fr);
        grid-column-gap: ${({ theme }) => theme.spacing(0.25)};
        grid-row-gap: ${({ theme }) => theme.spacing()};

        ${media.tablet`
          grid-column-gap: ${({ theme }) => theme.spacing()};
          grid-template-columns: repeat(1, 1fr);
        `}

        ${media.medium`
          grid-column-gap: ${({ theme }) => theme.spacing(2)};
          grid-template-columns: repeat(${props => props.column || 3}, 1fr);
        `}

        ${media.desktop`
          grid-column-gap: ${({ theme }) => theme.spacing(3)};
        `}
      `}

    ${props =>
      props.variant === 'locations' &&
      css`
        grid-template-columns: minmax(200px, 1fr);
        grid-column-gap: ${({ theme }) => theme.spacing(1.5)};
        grid-row-gap: ${({ theme }) => theme.spacing(1.5)};

        ${media.tablet`
          grid-template-columns: repeat(3, minmax(200px, 1fr));
        `}
      `}

    ${props =>
      props.variant === 'hotels' &&
      css`
        grid-template-columns: minmax(200px, 1fr);
        grid-column-gap: ${({ theme }) => theme.spacing(2)};
        grid-row-gap: ${({ theme }) => theme.spacing(3)};

        ${media.tablet`
          grid-template-columns: repeat(2, 1fr);
        `}

        ${media.medium`
          grid-template-columns: repeat(3, 1fr);
        `}
      `}

  ${props =>
    props.variant === 'review' &&
    css`
      grid-template-columns: minmax(200px, 1fr);
      grid-column-gap: ${({ theme }) => theme.spacing(2)};
      grid-row-gap: ${({ theme }) => theme.spacing(3)};

      ${media.tablet`
        grid-template-columns: repeat(2, 1fr);
        `}
    `}

    ${props =>
      props.variant === 'about' &&
      css`
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: ${({ theme }) => theme.spacing(2)};
        grid-row-gap: ${({ theme }) => theme.spacing(3)};

        ${media.tablet`
          grid-template-columns: repeat(2, 375px);
          grid-column-gap: ${({ theme }) => theme.spacing(2)};
        `}
      `}
`;

export const SectionContainer = styled.div`
  width:100%;
  ${props =>
  props.background &&
  css`
    background-color: #F7F7F7;
  `}
  padding-bottom:50px;
  ${props => !props.noMarginBottom && css`
    margin-bottom: 100px;
  `}
  
`
export const Container = styled.div`
  background-color: transparent;
  display: block;
  padding: ${({ theme }) => theme.spacing()};
  margin: 0 auto;

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)};
  `};

  ${media.desktop`
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    margin-top: ${({ theme }) => theme.spacing(2)};
    padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) =>
    theme.spacing(4)};
    ${props =>
      props.reservation &&
      css`
        max-width: 100%;
        margin-top: -50px;
        min-height: 700px;
  
      `}
    ${props =>
      props.steps &&
      css`
        margin-top: -80px;
      `}
  `}

  > * {
    margin-bottom: 0;

    + * {
      margin-top: ${({ theme }) => theme.spacing()};
      margin-bottom: ${({ theme }) => theme.spacing(2)};
      ${props =>
        props.bottomSpace &&
        css`
          margin-bottom: ${({ theme }) => theme.spacing()};
        `}
    }
  }

  ${props =>
    props.noPadding &&
    css`
      padding: 0;

      ${media.tablet`
          padding: 0;
        `}

      ${media.desktop`
          padding: 0;
        `}
    `}

  ${props =>
    props.hasTopPadding &&
    css`
      padding-top: ${({ theme }) => theme.spacing(3)};
    `}

  ${props =>
    props.asBottomBorder &&
    css`
      padding-bottom: ${({ theme }) => theme.spacing(1.5)};
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    `}

  ${props =>
    props.noMargin &&
    css`
      margin: 0;

      ${media.desktop`
        width: auto;
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
      `}
    `}

  ${props =>
    props.small &&
    css`
      max-width: 768px;
      p {
        ${({ theme }) => theme.fontStyles.bodyLarger};
      }
    `}

  ${props =>
    props.GreenBackgrounded &&
    css`
      text-align: center;
      overflow: hidden;
      position: relative;
      background-color: rgba(115, 201, 146, 0.12);
      overflow: hidden;
      padding: ${({ theme }) => theme.spacing(1.25)};
      margin: 0;

      ${media.tablet`
        padding: 70px;
      `}

      button {
        margin: 24px auto 0 auto;
        padding: ${({ theme }) => theme.spacing()}
          ${({ theme }) => theme.spacing(0.5)};
      }

      div {
        max-width: 768px;
        margin: 0 auto;
        font-size: ${({ theme }) => theme.spacing(1.25)};
        line-height: ${({ theme }) => theme.spacing(2)};
        letter-spacing: 0.6px;
      }
    `}
`;

export const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  border: 2px solid #73c992;
  border-radius: 4px;
  color: ${({ theme }) => theme.text};
  padding: ${({ theme }) => theme.spacing(2)};
  width: 100%;

  svg {
    color: ${({ theme }) => theme.primary};
  }

  > * {
    margin-top: 0;

    + * {
      margin-top: ${({ theme }) => theme.spacing()};
    }
  }

  ${props =>
    props.variant === 'reverse' &&
    css`
      color: #fff;
      border: 0;
      background-color: ${({ theme }) => theme.primary};
      svg {
        color: #fff;
      }
    `};
`;

const TitleBlock = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export const BlockExplore = ({ variant }) => {
  const { t } = useTranslation();

  return (
    <Wrapper variant={variant}>
      <BedIcon size={20} />
      <TitleBlock>{t('ui.containers.activities')}</TitleBlock>
      <div>{t('ui.containers.hanoi')}</div>
      <Button variant={variant ? variant : 'secondary'}>
        {t('ui.containers.explore')}
      </Button>
    </Wrapper>
  );
};
