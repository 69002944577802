import React from 'react';
import styled, { css } from 'styled-components';
import { MdClose } from 'react-icons/md';

const switchDimension = 30;
const switchBorder = 1;
const triggerSize = 28;

const ToggleSwitchWrapper = styled.div`
  display: block;
  width: 60px;
  height: ${switchDimension}px;
  position: relative;
  border: ${switchBorder}px solid #73bc8e;
  border-radius: ${switchDimension}px;
  transition: border-color 150ms ease-out, background-color 150ms ease-out;
  [type='checkbox'] {
    display: none;
  }

  ${props =>
    props.checked &&
    css`
      background-color: ${({ theme }) => theme.primary};
    `};
`;

const ToggleSwitchTrigger = styled.a`
  position: absolute;
  display: flex;
  background-color: #fff;
  border: 1px solid #73bc8e;
  text-align: center;
  justify-content: center;
  top: 51%;
  transform: translateY(-50%);
  height: ${switchDimension}px;
  width: ${switchDimension}px;
  transition: left 150ms ease-out, background-color 150ms ease-out;
  border-radius: 100%;
  left: ${props => (props.checked ? 'calc(100% - ' + triggerSize + 'px)' : 0)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;

  &:before {
    position: absolute;
    top: -6px;
    right: -3 * ${switchDimension}px;
    bottom: -6px;
    left: -3 * ${switchDimension}px;
    content: '';
    display: block;
  }

  svg {
    color: #84d7a2;
    font-size: 28px;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const ToggleSwitchTrack = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0px;
  width: 100%;
  color: #fff;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
`;

const ToggleSwitch = ({ checked, onChange, id, bare, disabled }) => (
  <ToggleSwitchWrapper bare {...{ checked, disabled }}>
    <ToggleSwitchTrack
      htmlFor={id}
      onClick={onChange}
      {...{ checked, disabled }}
    />
    <ToggleSwitchTrigger id={id} onClick={onChange} {...{ checked, disabled }}>
      <MdClose />
    </ToggleSwitchTrigger>
  </ToggleSwitchWrapper>
);

ToggleSwitch.defaultProps = {
  checked: false,
  bare: false,
};

export default ToggleSwitch;
