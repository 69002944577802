import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import AuthContext from '../../contexts/auth';
import { useTranslation } from 'react-i18next';
import Button from '../UI/Button';
import Input from '../UI/Input';
import Separator from '../UI/Separator';
import FacebookLoginButton from './SocialAuth';

function Login({onLoginSuccess, forgotPassword}) {
  const { handleLogin } = useContext(AuthContext);
  const { t } = useTranslation();

  const handleSubmit = async (values, { setErrors }) => {
    try {
      await handleLogin(values);
      await onLoginSuccess();
    } catch (e) {
      if (e.response.status === 400) {
        setErrors({ password: t('modals.emailLogin.errors.invalid') });
      } else {
        setErrors({ password: t('modals.emailLogin.errors.error') });
      }
    }
  };

  const validateInput = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = t('modals.emailLogin.placeholder.email');
    }
    if (!values.password) {
      errors.password = t('modals.emailLogin.placeholder.password')
    }
    return errors;
  }

  return (
    <div>

      <FacebookLoginButton onLoginSuccess={onLoginSuccess}/>

      <div className="my-10" style={{display:'none'}}>
        <Separator>{t('modals.emailLogin.or')}</Separator>
      </div>

      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validate={validateInput}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({isSubmitting}) => (
          <Form>
            <div className="mb-8">
              <Input
                label={t('modals.emailLogin.label.email')}
                name="email"
                type="email"
                placeholder={t('modals.emailLogin.placeholder.email')}
              />
            </div>

            <div className="mb-6">
              <Input
                label={t('modals.emailLogin.label.password')}
                name="password"
                type="password"
                placeholder={t('modals.emailLogin.placeholder.password')}
              />
            </div>

            <div className="mb-10">
              <button
                type="button"
                className="text-brand-orange"
                onClick={() => forgotPassword()}
              >
                {t('utils.forgottenPassword')}
              </button>
            </div>

            <Button type="submit" full loading={isSubmitting}>
              {t('modals.emailLogin.buttons.logIn')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Login;
