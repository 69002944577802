// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-details-js": () => import("./../../../src/pages/booking-details.js" /* webpackChunkName: "component---src-pages-booking-details-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-payment-callback-js": () => import("./../../../src/pages/payment-callback.js" /* webpackChunkName: "component---src-pages-payment-callback-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-profile-walkthrough-js": () => import("./../../../src/pages/profile-walkthrough.js" /* webpackChunkName: "component---src-pages-profile-walkthrough-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-become-host-js": () => import("./../../../src/templates/become-host.js" /* webpackChunkName: "component---src-templates-become-host-js" */),
  "component---src-templates-cgv-js": () => import("./../../../src/templates/CGV.js" /* webpackChunkName: "component---src-templates-cgv-js" */),
  "component---src-templates-city-js": () => import("./../../../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-confirmation-js": () => import("./../../../src/templates/confirmation.js" /* webpackChunkName: "component---src-templates-confirmation-js" */),
  "component---src-templates-countries-selection-js": () => import("./../../../src/templates/countries-selection.js" /* webpackChunkName: "component---src-templates-countries-selection-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-hotel-review-js": () => import("./../../../src/templates/hotel-review.js" /* webpackChunkName: "component---src-templates-hotel-review-js" */),
  "component---src-templates-hotels-js": () => import("./../../../src/templates/hotels.js" /* webpackChunkName: "component---src-templates-hotels-js" */),
  "component---src-templates-hotels-selection-js": () => import("./../../../src/templates/hotels-selection.js" /* webpackChunkName: "component---src-templates-hotels-selection-js" */),
  "component---src-templates-how-it-works-js": () => import("./../../../src/templates/how-it-works.js" /* webpackChunkName: "component---src-templates-how-it-works-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-manifesto-js": () => import("./../../../src/templates/manifesto.js" /* webpackChunkName: "component---src-templates-manifesto-js" */),
  "component---src-templates-politique-confidentialite-js": () => import("./../../../src/templates/politique-confidentialite.js" /* webpackChunkName: "component---src-templates-politique-confidentialite-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-sustainable-agreements-js": () => import("./../../../src/templates/sustainable-agreements.js" /* webpackChunkName: "component---src-templates-sustainable-agreements-js" */),
  "component---src-templates-sustainable-lodging-js": () => import("./../../../src/templates/sustainable-lodging.js" /* webpackChunkName: "component---src-templates-sustainable-lodging-js" */)
}

