import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import {
  Grid,
  Cell,
  List as RawList,
  TextLink,
  media,
} from '../ui';
import { Link } from '@reach/router';
import { Container } from '../ui/containers';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';
import PageContext from '../contexts/page';
import IcInstagram from '../assets/images/icons/IcInstagram';
import IcFacebook from '../assets/images/icons/IcFacebook';
import IcLinkedIn from '../assets/images/icons/IcLinkedIn';
import CountriesContext from '../contexts/country';
import FooterNewsletter from './FooterNewsletter';
import FooterLogo from './FooterLogo';

const SubFooter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  margin-top: -3px;
  background-color: ${({ theme }) => theme.footerBg};
  color: #fff;
  padding-bottom: calc(32px + 34px);
  padding-top: ${({ theme }) => theme.spacing()};
  font: normal normal normal 14px Muli;
  ${media.desktop`
    padding-bottom: ${({ theme }) => theme.spacing()};
    align-items: baseline;
    flex-direction: row;
    justify-content: space-between;
  `}

  button {
    color: #fff;
    width: 172px;
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const List = styled(RawList)`
  ${TextLink} {
    color: #fff;
    font: normal normal 14px Muli;
  }
`;

const PoliciesList = styled(List)`
  text-align: left;
  padding: 0 ${({ theme }) => theme.spacing()};

  ${TextLink} {
    margin-left: 0;
    display: block;
  }
  
  span {
    color: #fff;
    font: normal normal normal 14px/18px Muli;
    margin-right: ${({ theme }) => theme.spacing(2)};
  }

  ${media.desktop`
    padding-left:80px !important;
    margin-top:-60px;
    ${TextLink} {
      margin-left: ${({ theme }) => theme.spacing(3)};
    }
  `};
  
  ${media.tablet`
    ${TextLink} {
      display: inline;
      padding-left:50px !important;
    }
  `};
`;

const FooterContainer = styled.footer`
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.footerBg};
  padding: ${({ theme }) => theme.spacing(2)} 0;
  font-size: 25px;
  z-index: 11;
`;

const LinkLogo = styled.a`
  cursor: pointer;
  outline: none;
  text-decoration: none;
  color: #fff;
  margin-top: ${({ theme }) => theme.spacing()};
  margin-left: ${({ theme }) => theme.spacing(2.5)};

  > svg {
    width: 25px;

    &:hover {
      color: ${({ theme }) => theme.primary};
    }
    transition: all 150ms ease;
  }

  ${props =>
    props.left &&
    css`
      margin-left: 0;
    `}
`;

const Logos = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 0;
  margin-right: 10%;
  ${media.desktop`
    justify-content: right !important;
    padding-bottom: 0;
    
  `};

  ${media.tablet`
    padding-bottom: ${({ theme }) => theme.spacing()};
    align-items: baseline;
    padding-left: 15px;
    margin-bottom: 30px;
  `}
  
  ${media.mobile`
    padding-bottom: ${({ theme }) => theme.spacing()};
    align-items: baseline;
    padding-left: 15px;
    margin-bottom: 30px;
  `}
  ${props => props.bottom && css``}
`;

export const CheckboxText = styled.div`
  color: ${({ theme }) => theme.textLighter};
  font-size: 12px;
  letter-spacing: 0.51px;
  line-height: 16px;
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

export const CheckboxBlock = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing()};
  ${media.desktop`
    margin-top: 0;
  `}
`;

const FooterContent = styled(Container)`
  display: flex;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spacing()};
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  ${TextLink} {
    font-weight: bold !important;
  }
  ${({ variant }) =>
    variant === 'home' &&
    css`
      ${media.desktop`
      `};
    `}
`;

const CountryHotelsLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: -${({ theme }) => theme.spacing()};
  margin-top: ${({ theme }) => theme.spacing()};

  > * {
    display: block;
    margin-left: ${({ theme }) => theme.spacing()};
  }
`;

const CountryHotelsLink = ({ countries, countryTitle }) => {
  const { t } = useTranslation();
  const { getLanguagePrefix } = useContext(PageContext);
  const country = find(countries, { title: countryTitle });

  return (
    <TextLink
      as={Link}
      to={`${getLanguagePrefix(t('url.hotels'))}?countryId=${
        country?.originalId
      }`}
    >
      {country?.title}
    </TextLink>
  );
};

const Footer = ({ variant }) => {
  const { t } = useTranslation();
  const { getLanguagePrefix } = useContext(PageContext);
  const { countries } = useContext(CountriesContext);

  return (
    <div className="relative w-full">
      {variant === 'home' && (
        <FooterNewsletter/>
      )}

      <FooterContainer variant={variant}>
        <FooterContent>
          <Grid>
            <Cell sizes={{ default: 1, desktop: 1 / 4 }}>
              <FooterLogo/>
            </Cell>

            <Cell sizes={{ default: 1, desktop: 1 / 4 }}>
              <div className="text-white text-xs font-bold opacity-75 uppercase mb-4">
                {t('newFooter.about.title')}
              </div>
              <List isLight>
                <li>
                  <TextLink to={getLanguagePrefix(t('url.aboutUs'))}>
                    {t('newFooter.about.whoAreWe')}
                  </TextLink>
                </li>
                <li>
                  <TextLink to={getLanguagePrefix(t('url.manifesto'))}>
                    {t('newFooter.about.manifesto')}
                  </TextLink>
                </li>
                <li>
                  <TextLink to={getLanguagePrefix(t('url.sustainableHotel'))}>
                    {t('newFooter.about.whatsDurable')}
                  </TextLink>
                </li>
                <li>
                  <TextLink to={getLanguagePrefix(t('url.sustainable'))}>
                    {t('newFooter.about.ourDemarche')}
                  </TextLink>
                </li>
                <li>
                  <TextLink to={getLanguagePrefix(t('url.faq'))}>
                    {t('newFooter.about.faq')}
                  </TextLink>
                </li>
              </List>
            </Cell>

            <Cell sizes={{ default: 1, desktop: 1 / 4 }}>
              <div className="text-white text-xs font-bold opacity-75 uppercase">
                {t('newFooter.destinations.title')}
              </div>
              <List>
                <CountryHotelsLinks>
                  <li>
                    <TextLink as={Link} to={getLanguagePrefix(t('url.countries'))}>
                      {t('newFooter.destinations.all')}
                    </TextLink>
                  </li>
                  <li>
                    <CountryHotelsLink
                      countries={countries}
                      countryTitle="France"
                    />
                  </li>
                  <li>
                    <CountryHotelsLink
                      countries={countries}
                      countryTitle="Espagne"
                    />
                  </li>
                  <li>
                    <CountryHotelsLink
                      countries={countries}
                      countryTitle="Italie"
                    />
                  </li>
                  <li>
                    <CountryHotelsLink
                      countries={countries}
                      countryTitle="Portugal"
                    />
                  </li>
                  <li>
                    <CountryHotelsLink
                      countries={countries}
                      countryTitle="Grèce"
                    />
                  </li>
                </CountryHotelsLinks>
              </List>
            </Cell>

            <Cell sizes={{ default: 1, desktop: 1 / 4 }}>
              <div className="text-white text-xs font-bold opacity-75 uppercase mb-4">
                {t('newFooter.feelingooder.title')}
              </div>
              <List isLight>
                <li>
                  <TextLink to={getLanguagePrefix(t('url.becomeHost'))}>
                    {t('newFooter.feelingooder.beHote')}
                  </TextLink>
                </li>
                <li>
                  <TextLink to={getLanguagePrefix(t('url.articles'))}>
                    {t('newFooter.feelingooder.travel')}
                  </TextLink>
                </li>
                <li>
                  <TextLink
                    as="a"
                    href="https://storage.googleapis.com/koob-prod/Liens%20PDF%20sur%20feelingo.com/DossierPresse_Avril2021_Feelingo_V1.1%20(1).pdf"
                  >
                    {t('newFooter.feelingooder.press')}
                  </TextLink>
                </li>
              </List>
            </Cell>
          </Grid>
        </FooterContent>
      </FooterContainer>

      <SubFooter>
      <PoliciesList isLight marg>
          <span>{t('newFooter.policiesConsent.rights')}</span>

          <TextLink to={getLanguagePrefix('CGV')}>
            {t('newFooter.policiesConsent.mention')}
          </TextLink>

          <TextLink to={getLanguagePrefix('CGV')}>
            {t('newFooter.policiesConsent.CGV')}
          </TextLink>

          <TextLink to={getLanguagePrefix(t('url.privacy'))}>
            {t('newFooter.policiesConsent.confidentiality')}
          </TextLink>
        </PoliciesList>
        <Logos>
          <LinkLogo href="https://www.instagram.com/feelingo.officiel/" left target="_blank">
            <IcInstagram />
          </LinkLogo>

          <LinkLogo href="https://www.facebook.com/Feelingo.officiel/" target="_blank">
            <IcFacebook />
          </LinkLogo>

          <LinkLogo href="https://www.linkedin.com/company/feelingo-officiel/about/" target="_blank">
            <IcLinkedIn />
          </LinkLogo>
        </Logos>
      </SubFooter>
      
    </div>
  );
};

export default Footer;
