import { get } from './';

export const search = ({ query, language, excludes }) =>
  get('/api/v1/koob/search', { lang: language, options: { query, excludes } });

export const types = ({ language }) =>
  get('/api/v1/koob/types', { lang: language });

export const styles = ({ language }) =>
  get('/api/v1/koob/styles', { lang: language });

export const categories = ({ language }) =>
  get('/api/v1/koob/categories', { lang: language });

export const localisations = ({ language }) =>
  get('/api/v1/koob/locations', { lang: language });
