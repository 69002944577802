import React, { useContext, useState } from 'react';
import AuthContext from '../../contexts/auth';
import { useTranslation } from 'react-i18next';
import Button from '../UI/Button';

function FacebookLoginButton({onLoginSuccess}) {
  const { handleFacebookLogin: onFacebookLogin } = useContext(AuthContext);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleFacebookLogin = async () => {
    setLoading(true);
    await onFacebookLogin();
    await onLoginSuccess();
    setLoading(false);
  };

  return (
    <Button onClick={() => handleFacebookLogin()} variant="facebook" loading={loading} full style={{display:'none'}}>
      {t('modals.loginChoices.buttons.facebook')}
    </Button>
  )
}

export default FacebookLoginButton;
