import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Dropdown, Stack } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import {
  MdExpandMore,
  MdAddCircleOutline,
  MdRemoveCircleOutline,
} from 'react-icons/md';
import { ButtonReset, media } from 'ui';
import { PopoverContainer } from 'ui/popover';
import {
  Wrapper,
  Trigger,
  PlaceHolderLabel,
  ValueWrapper,
  Icon,
} from 'ui/select';

const AgeCategoryDetail = styled.dl`
  margin: 0;
  dt {
    ${({ theme }) => theme.fontStyles.h3};
  }

  dd {
    margin-left: 0;
    color: ${({ theme }) => theme.textLight};
    ${({ theme }) => theme.fontStyles.body};
  }
`;

const AgesContainer = styled(PopoverContainer)`
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing(0.75)};

  ${media.tablet`
    width: max-content;
    max-height: initial;
    overflow: auto;
    padding: ${({ theme }) => theme.spacing()}
      ${({ theme }) => theme.spacing(1.5)};
  `}

  ${media.desktop`
    width: max-content;
  `}
`;

const AgeCategories = styled(Stack).attrs({
  direction: 'column',
})`
  > * {
    padding: ${({ theme }) => theme.spacing()} 0;
  }
  > * + * {
    border-top: 1px solid ${({ theme }) => theme.separator};
  }
`;

const StepperWrapper = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 1,
})`
  flex-shrink: 0;
  ${({ theme }) => theme.fontStyles.h3};
  font-weight: normal;

  svg {
    font-size: ${({ theme }) => theme.size(1.5)};
  }
  will-change: transform;

`;

const AgeCategory = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 3,
  alignX: 'space-between',
})`
  min-width: 100%;
`;

const Stepper = ({ value, onIncrement, onDecrement }) => {
  return (
    <StepperWrapper>
      <ButtonReset
        onClick={e => {
          e.preventDefault();
          onDecrement();
        }}
      >
        <MdRemoveCircleOutline />
      </ButtonReset>
      <div>{value}</div>
      <ButtonReset
        onClick={e => {
          e.preventDefault();
          onIncrement();
        }}
      >
        <MdAddCircleOutline />
      </ButtonReset>
    </StepperWrapper>
  );
};

const SearchAges = ({ variant, ...props }) => {
  const { t } = useTranslation();
  const [field, , { setValue }] = useField(props);
  const { adults = 0, children = 0, infants = 0 } = field.value || {};

  const valueForDisplay = !props.simplified ?[
    ...(adults > 0
      ? [t('searchBar.ageCategories.adultsValue', { count: adults })]
      : []),
    ...(children > 0
      ? [t('searchBar.ageCategories.childrenValue', { count: children })]
      : []),
    ...(infants > 0
      ? [t('searchBar.ageCategories.infantsValue', { count: infants })]
      : []),
  ]:[
    t('searchBar.ageCategories.allValue', { count: adults+children+infants })
  ]
  const defaultText=props.placeholder?props.placeholder:'';
  const displayedValue = valueForDisplay.length>0?valueForDisplay.join(', '):defaultText;

  return (
    <Dropdown
      wrapperStyle={variant === 'homeSearch'?{height:'100%'}:{}}
      trigger={
        <Wrapper variant={variant}>
            {
              <Trigger variant={variant}>
                <PlaceHolderLabel variant={variant}>
                  <ValueWrapper variant={variant} isEmpty={displayedValue===defaultText}>{displayedValue}</ValueWrapper>
                </PlaceHolderLabel>
                {variant !== 'home' ? (<Icon>
                  <MdExpandMore color={!props.widgetAge ? "#73c992" : "#FFA24B"} size={20} />
                </Icon>) : undefined
                }
              </Trigger>
            }
        </Wrapper>
      }
    >
      <AgesContainer variant={variant}>
        <AgeCategories>
          <AgeCategory>
            <AgeCategoryDetail>
              <dt>{t('searchBar.ageCategories.adults')}</dt>
              <dd>{t('searchBar.ageCategories.adultsAge')}</dd>
            </AgeCategoryDetail>
            <Stepper
              value={adults}
              onDecrement={() =>
                adults > 0 &&
                setValue({
                  ...field.value,
                  adults: adults - 1,
                })
              }
              onIncrement={() =>
                setValue({
                  ...field.value,
                  adults: adults + 1,
                })
              }
            />
          </AgeCategory>
          <AgeCategory>
            <AgeCategoryDetail>
              <dt>{t('searchBar.ageCategories.childs')}</dt>
              <dd>{t('searchBar.ageCategories.childsAge')}</dd>
            </AgeCategoryDetail>
            <Stepper
              value={children}
              onDecrement={() =>
                children > 0 &&
                setValue({
                  ...field.value,
                  children: children - 1,
                })
              }
              onIncrement={() =>
                setValue({
                  ...field.value,
                  children: children + 1,
                })
              }
            />
          </AgeCategory>
          <AgeCategory>
            <AgeCategoryDetail>
              <dt>{t('searchBar.ageCategories.babys')}</dt>
              <dd>{t('searchBar.ageCategories.babysAge')}</dd>
            </AgeCategoryDetail>
            <Stepper
              value={infants}
              onDecrement={() =>
                infants > 0 &&
                setValue({
                  ...field.value,
                  infants: infants - 1,
                })
              }
              onIncrement={() =>
                setValue({
                  ...field.value,
                  infants: infants + 1,
                })
              }
            />
          </AgeCategory>
        </AgeCategories>
      </AgesContainer>
    </Dropdown>
  );
};

export default SearchAges;
