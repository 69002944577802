/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React, { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { GTMProvider } from '@tymate/react-gtm';
import Modal from 'react-modal';
import AuthProvider from './src/providers/AuthProvider';
import PageProvider from './src/providers/PageProvider';
import CountriesProvider from './src/providers/CountriesProvider';
import UtilsProvider from './src/providers/UtilsProvider';
import { theme } from './src/ui/colors';
import {
  TCFProvider
} from '@tymate/react-quantcast';
import { QUANTCAST_ID } from './src/constants';
import NextBookingsTracking from './src/components/NextBookingsTracking';

Modal.setAppElement(`#___gatsby`);

export const wrapPageElement = ({
  element,
  props: { data, pageContext, location },
}) => {
  const { language } = pageContext;

  return (
    <Suspense fallback={<div />}>
      <TCFProvider
        accountId={QUANTCAST_ID}
        customVendorsMapping={{
          1: 'vendor.facebook',
          2: 'vendor.google_analytics',
        }}
      >
        {({ purposes, customVendors }) => (
          <PageProvider pageContext={pageContext}>
            <ThemeProvider theme={theme}>
              <CountriesProvider>
                <AuthProvider>
                  {({ isAuthenticated, user }) => (
                    <GTMProvider
                      data={data}
                      location={location}
                      initialDataLayer={{
                        language,
                        isAuthenticated,
                        isClient: (user?.bookings || []).length
                          ? 'client'
                          : 'prospect',
                        customVendors,
                      }}
                    >
                      <UtilsProvider>{element}</UtilsProvider>
                      <NextBookingsTracking />
                    </GTMProvider>
                  )}
                </AuthProvider>
              </CountriesProvider>
            </ThemeProvider>
          </PageProvider>
        )}
      </TCFProvider>
    </Suspense>
  );
};
