import uuidv5 from 'uuid/v5';
import qs from 'qs';
import { flatten, orderBy, deburr } from 'lodash';
import { DEFAULT_LANGUAGE } from '../constants';

export const ERRORS = {
  REQUIRED: 'Ce champ est requis',
  EMAIL: 'Cette adresse mail n’est pas valide',
};

const UUID_SEED = '930521AE-D568-4514-B454-8414185EE911';

export const pluralize = (input, count) =>
  input
    .split(' ')
    .map(i => `${i}${count > 1 ? 's' : ''}`)
    .join(' ');

export const monthsList = [
  { label: 'Janvier', value: 0 },
  { label: 'Février', value: 1 },
  { label: 'Mars', value: 2 },
  { label: 'Avril', value: 3 },
  { label: 'Mai', value: 4 },
  { label: 'juin', value: 5 },
  { label: 'Juillet', value: 6 },
  { label: 'Août', value: 7 },
  { label: 'Septembre', value: 8 },
  { label: 'Octobre', value: 9 },
  { label: 'Novembre', value: 10 },
  { label: 'Décembre', value: 11 },
];

// Natural Language Forms

export const naturalOptions = [
  { label: 'indifférent je ne mange pas le matin', value: 0 },
  { label: 'crucial', value: 1 },
];

export const getLanguagePrefix = (language, url = '') => {
  const prefix =
    !Boolean(language) || language === DEFAULT_LANGUAGE ? '' : `${language}/`;

  if (language === DEFAULT_LANGUAGE) {
    return `/${url}`;
  }

  const prefixedUrl = `/${prefix}${url}`;
  if (prefixedUrl.match(/(.*)\/$/)) {
    return `/${prefix}${url}`.match(/(.*)\/$/)[1];
  }
  return prefixedUrl;
};

export const getNodeId = (type, id, language = null) =>
  uuidv5([type, language, id].filter(Boolean).join('-'), UUID_SEED);

export const formatSearch = search => qs.stringify(search);
export const parseSearch = query => qs.parse(query);

export const toLowerWithoutAccents = value =>
    value.toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y');
        
/*export const formatSearch = search =>
  keys(decamelizeKeys(search))
    .filter(key => Boolean(decamelizeKeys(search)[key]))
    .filter(key => {
      if (!isString(decamelizeKeys(search)[key])) {
        return true;
      }
      return !decamelizeKeys(search)[key].includes('*');
    })
    .map(key => `${key}:${decamelizeKeys(search)[key]}`)
    .join(',');

export const parseSearch = query =>
  Boolean(query)
    ? camelizeKeys(
        query
          .replace('?', '')
          .split(',')
          .filter(item => Boolean(item))
          .reduce(
            (obj, item) => ({
              ...obj,
              [item.split(':')[0]]: item.split(':')[1],
            }),
            {},
          ),
      )
    : {};*/

export const getItemAsArray = item => flatten([item]);

export const ABCdaryCertifications = [
  {
    displayName: 'Green Globe Business Certification',
    content:
      'Green globe est une certification spécialement conçue pour le secteur du tourisme. Créé en 1993, elle est présente dans plus de 80 pays autour du globe, avec plus de 500 membres engagés et certifiés. Elle valorise les performances et les efforts, et est accessible à une grande variété d’activités touristiques (hôtels, transports, tours opérateurs...). Afin d’obtenir la certification, un établissement doit se conformer à 44 critères centraux, évalués par plus de 380 indicateurs. Ceux-ci sont divisés en 4 catégories : Management environnemental, Économique et social, Héritage culturel et Environnement. De plus, la totalité du programme appelé « Green Globe Standard » est revue et mis à jour deux fois par an. Un membre peut accéder à 3 niveaux : Certified (50 % des critères remplis), Gold (certifié pendant 5 ans et 100 % des critères) et Platinium (100 % des critères et 10 ans de certification). Cette certification garantit la conformité à certains standards internationaux tels que les GSTC, GPST, l’Agenda 21 ou encore les normes ISO.',
    picture: '',
  },
  {
    displayName: 'Green Key Global',
    content:
      'Ce programme d’évaluation écologique évalue depuis plus de 20 ans les hôtels et les industries du tourisme d`affaire selon leurs initiatives prises au niveau du développement durable. Reconnue par le GSTC, ce système de notation les aide à améliorer leurs performances durables. Pour cela, Green Key Global fournit un guide pour établir des axes d’amélioration sur des critères tels que la consommation, les déchets, les émissions, les engagements, la chaîne d’approvisionnement et plus encore. Cela permet aux 1 600 sites certifiés dans 20 pays différents d’avoir des possibilités et des idées d’actions en termes de management environnemental.',
    picture: '',
  },
  {
    displayName: 'Green Key',
    content:
      'La clef verte est une certification créée en 1994 au Danemark et approuvée par le programme de la FEE (Fondation pour l’éducation environnementale) depuis 2002. De par son lien avec cet ONG elle place le management, l’éducation et la sensibilisation en matière d’environnementaux comme base de ses plus de 100 critères. Rassemblés en 13 sections (implication des salariés, management environnemental, information des clients, eau, énergie, ménage, alimentation, déchets, administration, environnement interne, espaces verts, activités vertes, responsabilité sociale des entreprises) les critères servent à déterminer l’engagement de l’hôtel quant à la certification.  Reconnue par le GSTC et présente dans 57 pays, cette certification concerne plus de 2 800 sites.',
    picture: '',
  },
  {
    displayName: 'Lorem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Corem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Eorem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Horem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Iorem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Jorem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Korem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Lorem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Morem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Norem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Porem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Qorem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Sorem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Torem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Vorem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Xorem Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
  {
    displayName: 'Conscience Certification',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit velit, lobortis vitae scelerisque vel, ullamcorper id justo. Nulla non ipsum mauris. Mauris feugiat massa sit amet eros pellentesque, nec dignissim risus rutrum. Ut lectus nibh, tempor nec ornare vitae, dapibus non odio. Aliquam bibendum consectetur felis id placerat. Sed tristique malesuada turpis ac auctor. Sed pellentesque, arcu nec vehicula placerat, nisl purus porttitor felis, non volutpat neque ligula at est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse molestie lectus lectus, sed porttitor mauris malesuada vitae. In hac habitasse platea dictumst. In varius lobortis eleifend.',
    picture:
      'https://centmillemilliards.com/wp/wp-content/uploads/2013/02/images_Lorem-Ipsum-editions-cent-mille-milliards.jpg',
  },
];

export const getABCdaryObjectArray = certifications => {
  const alphabet = [];
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').forEach(letter =>
    alphabet.push({
      letter,
      certifications: orderBy(
        (certifications || []).filter(
          ({ displayName }) =>
            displayName && (displayName || [])[0].toUpperCase() === letter,
        ),
        ({ displayName }) => deburr(displayName),
      ),
    }),
  );

  return alphabet;
};
