const i18n = require('./i18n');

exports.wrapPageElement = ({ element, props: { pageContext } }) => {
  const language = pageContext.language || null;

  if (language && !i18n.hasResourceBundle(language, 'translation')) {
    i18n.services.resourceStore.data = pageContext.i18n.resources;

    // add namespaces to the config - so a languageChange call loads all namespaces needed
    i18n.options.ns = Object.values(pageContext.i18n.resources).reduce(
      (mem, lngResources) => {
        Object.keys(lngResources).forEach(ns => {
          if (mem.indexOf(ns) < 0) mem.push(ns);
        });
        return mem;
      },
      i18n.options.ns,
    );

    i18n.initializedStoreOnce = true;
    i18n.isInitialized = true;
  }

  if (language) {
    i18n.changeLanguage(language);
  }

  return element;
};
