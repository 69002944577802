import React from 'react';
import { Link } from 'gatsby';
import ConditionalWrapper from '../ConditionalWrapper';

export default function Button(props) {
  const paddingClass = () => {
    return props.paddingClass ?? 'py-4 px-8';
  }

  const colorClass = () => {
    if (props.variant === 'facebook')
      return 'bg-facebook text-white';

    return 'bg-brand-orange text-brand-black';
  }

  const disabledClass = () => {
    return props.disabled && 'opacity-75';
  }

  const widthClass = () => {
    return props.full && 'w-full';
  }

  return (
    <div className="tw">
      <ConditionalWrapper
        condition={props.asLink || props.to}
        wrapper={children => <Link to={props.to}>{children}</Link>}
      >
        <button
          className={[
            'font-semibold rounded-full',
            paddingClass(),
            colorClass(),
            disabledClass(),
            widthClass(),
            props.childClass
          ].join(' ')}
          type={props.type ?? 'button'}
          disabled={props.disabled || props.loading}
          {...props}
        >
          {!props.loading ? (
            props.children
          ) : (
            <div className={`spinner ${props.variant === 'facebook' && 'light'}`}>
              <div className="bounce1"/>
              <div className="bounce2"/>
              <div className="bounce3"/>
            </div>
          )}
        </button>
      </ConditionalWrapper>
    </div>
  )
}
