import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { keys, round, toPairs } from 'lodash';

export const VIEWPORT_SIZES = {
  desktop: 1100,
  medium: 1000,
  tabletprod: 900,
  tablet: 750,
};

const mediaQuery = (...query) => (...rules) =>
  css`
    @media ${css(...query)} {
      ${css(...rules)};
    }
  `;

export const media = {
  tablet: mediaQuery`(min-width: ${VIEWPORT_SIZES.tablet / 16}em)`,
  medium: mediaQuery`(min-width: ${VIEWPORT_SIZES.medium / 16}em)`,
  tabletprod: mediaQuery`(min-width: ${VIEWPORT_SIZES.tabletprod / 16}em)`,
  desktop: mediaQuery`(min-width: ${VIEWPORT_SIZES.desktop / 16}em)`,
  mobile: mediaQuery`(max-width: ${VIEWPORT_SIZES.tablet / 16}em)`,
};

const cssLock = ({
  valueUnit = '',
  minValue,
  maxValue,
  minViewportWidth = VIEWPORT_SIZES.tablet,
  maxViewportWidth = VIEWPORT_SIZES.desktop,
}) =>
  `calc((${minValue} * 1${valueUnit}) + (${maxValue} - ${minValue}) * (100vw - ${minViewportWidth /
    16}em) / (${maxViewportWidth / 16} - ${minViewportWidth / 16}))`;

const fontSizes = {
  h1Ultra: {
    sizeMinRem: 2,
    sizeMaxRem: 4,
    lineHeightMin: 1.2,
    lineHeightMax: 1.1,
    fontWeight: 700,
    defaultTag: 'h1',
  },
  h1Mega: {
    sizeMinRem: 1.75,
    sizeMaxRem: 2,
    lineHeightMin: 1.2,
    lineHeightMax: 1.1,
    fontWeight: 700,
    defaultTag: 'h1',
  },
  h1: {
    sizeMinRem: 1.375,
    sizeMaxRem: 1.625,
    lineHeightMin: 1.25,
    lineHeightMax: 1.2,
    fontWeight: 700,
    defaultTag: 'h1',
  },
  h2: {
    sizeMinRem: 1.25,
    sizeMaxRem: 1.5,
    lineHeightMin: 1.25,
    lineHeightMax: 1.2,
    fontWeight: 700,
    defaultTag: 'h2',
  },
  h3: {
    sizeMinRem: 1.125,
    sizeMaxRem: 1.25,
    lineHeightMin: 1.4375,
    lineHeightMax: 1.25,
    fontWeight: 700,
    defaultTag: 'h3',
  },
  bodyLarger: {
    sizeMinRem: 1.0625,
    sizeMaxRem: 1.1875,
    lineHeightMin: 1.3,
    lineHeightMax: 1.5,
    fontWeight: 400,
    defaultTag: 'span',
  },
  bodyLarge: {
    sizeMinRem: 0.875,
    sizeMaxRem: 0.9375,
    lineHeightMin: 1.3,
    lineHeightMax: 1.5,
    fontWeight: 400,
    defaultTag: 'span',
  },
  body: {
    sizeMinRem: 0.8125,
    sizeMaxRem: 0.875,
    lineHeightMin: 1.35,
    lineHeightMax: 1.4,
    fontWeight: 400,
    defaultTag: 'span',
  },
  bodySmall: {
    sizeMinRem: 0.75,
    sizeMaxRem: 0.8125,
    lineHeightMin: 1.4,
    lineHeightMax: 1.35,
    fontWeight: 400,
    defaultTag: 'span',
  },
  bodySmaller: {
    sizeMinRem: 0.6875,
    sizeMaxRem: 0.75,
    lineHeightMin: 1.25,
    lineHeightMax: 1.35,
    fontWeight: 400,
    defaultTag: 'span',
  },
};

export const fontSize = toPairs(fontSizes).reduce(
  (acc, [key, { sizeMinRem, sizeMaxRem }]) => ({
    ...acc,
    [key]: css`
      font-size: ${sizeMinRem}rem;

      ${media.tablet`
        font-size: ${cssLock({
          valueUnit: 'rem',
          minValue: sizeMinRem,
          maxValue: sizeMaxRem,
        })};
      `}

      ${media.desktop`
        font-size: ${sizeMaxRem}rem;
      `}
    `,
  }),
  {},
);

export const lineHeight = toPairs(fontSizes).reduce(
  (acc, [key, { lineHeightMin, lineHeightMax }]) => ({
    ...acc,
    [key]: css`
      line-height: ${lineHeightMin}em;

      ${media.tablet`
        line-height: ${cssLock({
          valueUnit: 'em',
          minValue: lineHeightMin,
          maxValue: lineHeightMax,
        })};
      `}

      ${media.desktop`
        line-height: ${lineHeightMax}em;
      `}
    `,
  }),
  {},
);

export const fontWeight = toPairs(fontSizes).reduce(
  (acc, [key, { fontWeight }]) => ({
    ...acc,
    [key]: css`
      font-weight: ${fontWeight};
    `,
  }),
  {},
);

export const fontStyles = keys(fontSizes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: css`
      ${fontSize[key]}
      ${lineHeight[key]}
      ${fontWeight[key]}
    `,
  }),
  {},
);

export const base = 16;
export const spacing = (input = 1) => `${input * base}px`;
export const size = (input = 0) =>
  `${round(Math.sqrt(Math.sqrt(2)) ** input, 3)}rem`;

export const Title = styled.h1`
  ${({ theme }) => theme.fontStyles.h1};
  color: ${({ theme }) => theme.text};

  ${props =>
    props.bottom &&
    css`
      margin-bottom: 8px;
    `}

  ${props =>
    props.sustainable &&
    css`
      font-size: 1.925rem !important;
      margin-top: 90px;
    `}

    ${props =>
      props.destination &&
      css`
        font-size: 1.925rem !important;
        margin-top: 35px;
      `}
`;

export const Text = styled.span`
  color: #6C6C6C;
  font-size: 1rem !important;

  > div > ul > li {
    font-size: 1rem !important;
    margin-left: -19px;
  }
  > div > p  {
    font-size: 1rem !important;
  }
  > div > h2 {
    font-size: 1.925rem !important;
    margin-top: 35px;
    color: #000 ! important;
  }
`;

export const SubTitle = styled.h2`
  font-weight: 700;
  color: ${({ theme }) => theme.text};
  ${props =>
    props.sustainable &&
    css`
      font-size: 1.225rem !important;

      margin-top: 50px;
    `}
`;

export const Heading = styled.h2`
  font-weight: 400;
  color: ${({ theme }) => theme.text};
  margin: 0;

  ${props =>
    props.hasBottomMargin &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing()};
    `}
  ${props =>
    props.resultPage &&
    css`
      font-weight: bold;
      color: ${({ theme }) => theme.text};
      margin: 0;
      ${props =>
        props.bucketlist &&
        css`
          font-size: 18px;
          margin-top: 2px;
        `}
    `}
`;

export const SubHeading = styled.h3`
  font-weight: 400;
  color: ${({ theme }) => theme.text};

  ${props =>
    props.color === 'light' &&
    `
  color: #fff;
  `}
  ${props =>
    props.variant === 'quality' &&
    `
      font-weight: 700;
      text-align: center;
      font-size: 20px;
      color: #1A1A1A;
      opacity: 1;
    `}

    ${media.mobile`
      width: 70%;
      margin: auto;
      font-size: 22px;
      margin-top: 40px;
    `}
`;

export const PrimaryLink = styled(Link)`
  color: ${({ theme }) => theme.primary};
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  border: none;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  transition: color 150ms ease;

  &:hover {
    color: ${({ theme }) => theme.primaryLight};
  }

  ${props =>
    props.cityCard &&
    css`
      font-weight: 700;
      font-size: 11px;
      text-transform: uppercase;
      margin-left: 30px;
    `}

  ${props =>
    props.variant === 'black' &&
    css`
      color: #000;
    `}


  ${props =>
    props.underline &&
    css`
      text-decoration: underline;
    `}

  ${props =>
    props.hasTopMargin &&
    css`
      margin-top: ${({ theme }) => theme.spacing(2)};
    `}
`;

export const TextLink = styled(PrimaryLink)`
  white-space: nowrap;
  font-weight: 400 !important;
  background-color: transparent;

  ${({ color }) =>
    color === 'primary' &&
    `
      color: ${({ theme }) => theme.primary};
   `}

  ${({ color }) =>
    color === 'text' &&
    `
     color: #000;
  `}

  ${props =>
    props.left &&
    css`
      margin-left: 5rem;
    `}
`;

export const ButtonReset = styled.button`
  border: 0;
  background-color: transparent;
  text-decoration: none;
  color: inherit;
  outline: none;
  cursor: pointer;
  padding: 0;

  svg {
    display: flex;
    color: #000 !imporant;
  }

  ${props =>
    props.left &&
    css`
      text-align: left;
      svg {
        display: flex;
        color: #000 !imporant;
      }
    `}
`;

export const TextButton = styled(ButtonReset)`
  display: flex;
  font-size: 17px;
  padding: 0;
  margin: 0;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
    &:hover {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  height: 52px;

  ${props =>
    props.isLeftAlign &&
    css`
      justify-content: flex-start !important;
    `}

  ${props =>
    props.hasTopMargin &&
    css`
      margin-top: ${({ theme }) => theme.spacing(2)};
    `}

  ${props =>
    props.hasNoTopMargin &&
    css`
      margin-top: ${({ theme }) => theme.spacing(-2)};
    `}

  ${props =>
    props.isRightAlign &&
    css`
      justify-content: flex-end;
    `}

    ${props =>
      props.variant === 'article' &&
      css`
        display: none;
        transition: opacity 1s ease-out;
        opacity: 0;
      `}
      ${props =>
        props.variant === 'rangeSlider' &&
        css`
          width: 100%;
          align-items: center;
        `}

      ${props =>
        props.variant === 'accommodation' &&
        css`
          display: none;
          all 0.6s ease-in-out;
          opacity: 0.5;
      `}
`;

export const List = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  color: ${({ theme }) => theme.textLighter};

  ${props =>
    props.bold &&
    css`
      li {
        font-weight: 600;
      }
    `}

  ${props =>
    props.marg &&
    css`
      margin: -0.6rem 0 0.6rem 0;
    `}
`;

export const ButtonLink = styled(Link)`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  outline: none;
  border: 0;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 14px;


  ${props =>
    props.variant === 'hero' &&
    css`
      border: 2px solid #fff;
      color: #fff;
      border-radius: 4px;
      padding: 0 ${({ theme }) => theme.spacing(0.5)};
      width: auto;
      height: 48px;
      font-weight: 600;
    `}

  ${props =>
    props.variant === 'primary' &&
    css`
      background-color: ${({ theme }) => theme.primary};
      border-radius: 4px;
      padding-right: ${({ theme }) => theme.spacing()};
      padding-left: ${({ theme }) => theme.spacing()};
      color: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
      min-width: 190px;
      font-size: 16px;
      font-weight: 800;
      height: 100%;
    `}

    ${props =>
      props.variant === 'secondary' &&
      css`
        background-color: transparent;
        border-radius: 4px;
        padding-right: ${({ theme }) => theme.spacing()};
        padding-left: ${({ theme }) => theme.spacing()};
        color: ${({ theme }) => theme.primary};
        border: 2px solid ${({ theme }) => theme.primary};
        min-width: 190px;
        font-size: 16px;
        font-weight: 800;
        height: 50px;
      `}
`;

export const Button = styled.button`
  ${({ theme }) => theme.fontStyles.body}
  display: flex;
  align-items: center;
  text-align: center;
  background: none;
  justify-content: center;
  text-decoration: none;
  outline: none;
  border: 0;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;
  transition: all 150ms ease;

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    pointer-events: none;
    color: ${({ theme }) => theme.textDisabled};
    border-color: ${({ theme }) => theme.separatorSoft};
  }


  ${props =>
    props.isRightAlign &&
    css`
      justify-content: right;
    `};

  ${props =>
    props.variant === 'accessible' &&
    css`
      background-color: #ffffff;
      border-radius: 4px;
      padding: ${({ theme }) => theme.spacing(0.5)};
      width: auto;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 600;
    `}

  ${props =>
    props.variant === 'outline' &&
    css`
      border: 1px solid #1a1a1a26 !important;
      border-radius: 28px;
      outline: none;
      cursor: pointer;
      font-weight: 800;
      color: #000000 !important;
      padding: ${({ theme }) => theme.spacing()};
      text-transform: none;
      height: 47px;
      width: 150px;
      ${props =>
        props.size === 'small' &&
        css`
          font-size: 12px;
          padding: ${({ theme }) => theme.spacing(0.5)};
        `}

      &:hover,
      &:active {
        border: 2px solid ${({ theme }) => theme.primaryLight}!important;
        color: ${({ theme }) => theme.primaryLight} !important;
      }
    `}
    ${props =>
      props.variant === 'booking' &&
      css`
        border: 1px solid #1a1a1a26 !important;
        border-radius: 28px;
        outline: none;
        cursor: pointer;
        font-weight: 600;
        color: #000000 !important;
        padding: ${({ theme }) => theme.spacing()};
        text-transform: none;
        height: 52px;
        width: 200px;

        ${props =>
          props.size === 'small' &&
          css`
            font-size: 12px;
            padding: ${({ theme }) => theme.spacing(0.5)};
          `}

        &:hover,
        &:active {
          border: 2px solid ${({ theme }) => theme.primaryLight}!important;
          color: ${({ theme }) => theme.primaryLight} !important;
        }
      `}

    ${props =>
      props.variant === 'outlineDurability' &&
      css`
        width: 157px !important;
        height: 50px !important;
        background: #ffffff 0% 0% no-repeat padding-box !important;
        border-radius: 28px !important;
        opacity: 1 !important;
        border: 2px solid ${({ theme }) => theme.primary} !important;
        outline: none;
        cursor: pointer;
        font-weight: 800;
        text-transform: none;
        font-size: 12px;
        padding: ${({ theme }) => theme.spacing()};
        letter-spacing: var(--unnamed-character-spacing-0) !important;
        color: #ff7f00;
        ${props =>
          props.size === 'small' &&
          css`
            font-size: 12px;
            padding: ${({ theme }) => theme.spacing(0.5)};
          `}

        &:hover,
        &:active {
          border: 2px solid ${({ theme }) => theme.primaryLight}!important;
          color: ${({ theme }) => theme.primaryLight} !important;
        }
      `}



  ${props =>
    props.variant === 'primary' &&
    css`
      background-color: ${({ theme }) => theme.primary};
      border-radius: 4px;
      padding: ${({ theme }) => theme.spacing(0.5)}
        ${({ theme }) => theme.spacing()};
      color: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
      font-weight: 600;
      height: 100%;

      &:hover,
      &:active {
        background-color: ${({ theme }) => theme.primaryLight};
      }
    `}
    
  ${props =>
    props.variant === 'hotelAccess' &&
    css`
      background-color: white;
      border-radius: 25px;
      padding: ${({ theme }) => theme.spacing(0.5)}
        ${({ theme }) => theme.spacing()};
      color: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
      color: black;
      height: 40px;
      font-weight: bold;
      text-transform: none;
    `}

    ${props =>
      props.variant === 'carnet' &&
      css`
        background-color: #ffa24b;
        text-transform: none;
        border-radius: 28px;
        margin-left: auto;
        color: #1a1a1a;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
        font-weight: bold;
        border: 1px solid #ffa24b;
        width: 166px;
        height: 50px;

        ${props =>
          props.article &&
          css`
            margin-left: 0;
            margin-top: 10px;
            width: 117px;
            height: 42px;
          `}

          ${props =>
            props.bucketlist &&
            css`
              justify-content: center !important;
              float: left;
              margin-top: 50px !important;
              margin-bottom: 40px !important;
              ${media.mobile`
                margin-left: 20px;
              `}
            `}

        ${props =>
          props.allArticles &&
          css`
            margin: auto;
            width: 50%;
            height: 47px;
          `}

        ${props =>
          props.hotels &&
          css`
            margin: auto;
            width: 287px;
            height: 47px;
          `}
        ${props =>
          props.sustainablebtn &&
          css`
            margin-left: 0px;
            width: 196px;
            padding: 10px;
            font-size: 0.845rem !important;
            padding-right: 12px;
            text-align: center;
            justify-content: center !important;
            padding: 25px 50px 25px;
          `}
        ${props =>
          props.sustainable &&
          css`
            width: 247px;
            font-size: 20px;
            height: 47px;
            margin: auto;
            margin-top: 50px;

            ${media.mobile`
            width: 247px;
            font-size: 18px;
          `}
            ${media.desktop`
            width: 247px;
        `}
          `}
    `}
  
    ${props =>
      props.variant === 'popup' &&
      css`
        background-color: #ffa24b;
        text-transform: none;
        border-radius: 28px;
        margin-left: auto;
        color: #1a1a1a;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
        font-weight: bold;
        border: 1px solid #ffa24b;
        width: 166px;
        height: 50px;
        margin-bottom: 20px;
      `}
    
      ${props =>
        props.variant === 'pictureProfile' &&
        css`
          background-color: #ffa24b;
          text-transform: none;
          border-radius: 28px;
          color: #1a1a1a;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
          font-weight: bold;
          border: 1px solid #ffa24b;
          height: 50px;
          margin-top: 0px !important;
          width: 40%;
          justify-content: center !important;
          margin-right: 5%;
          ${media.mobile`
          width: 100%;
        `};
          ${props =>
            props.cagnotte &&
            css`
             margin-right: -16%;
            ${media.mobile`
              width: 90%;
              margin-right: -10px;
            `};
           `}

        `}

        ${props =>
          props.variant === 'updateProfile' &&
          css`
            border: 1px solid ${({ theme }) => theme.secondary};
            color: #000;
            font-weight: 700;

            box-shadow: 0;
            border-radius: 28px !important;
            min-width: auto;

            background-color: ${({ theme }) => theme.secondary};
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
            height: 100%;
            box-shadow: 0;
            width: 150px !important;
            justify-content: center !important;
            text-transform: capitalize;
          `}

  ${props =>
    props.variant === 'home' &&
    css`
      background-color: transparent;
      border: 2px solid #fff;
      color: #fff;
      border-radius: 4px;
      padding: ${({ theme }) => theme.spacing(0.5)};
      width: auto;
      font-weight: 600;
      height: 46px;

      ${props =>
        props.square &&
        css`
          background-color: ${({ theme }) => theme.primary};
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
          padding: 0;
          width: 100%;
          height: 100%;
          box-shadow: 0;
          border: none !important;
          border-radius: 0 4px 4px 0;
          min-width: auto;

          &:hover,
          &:active {
            background-color: ${({ theme }) => theme.primaryLight};
          }

          ${media.desktop`
            background-color: ${({ theme }) => theme.primary};
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
            height: 100%;
            padding: 0;
            box-shadow: 0;
            border: none;
            border-radius: 0 4px 4px 0;
            width: 80px;

          `}
        `}
    `}
    
    ${props =>
      props.variant === 'homeSearch' &&
      css`
        border: 1px solid ${({ theme }) => theme.secondary};
        color: #000;
        font-weight: 600;

        background-color: ${({ theme }) => theme.secondary} !important;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
        padding: 0 30px 0 30px !important;
        width: 100%;
        height: 100%;
        box-shadow: 0;
        border-radius: 28px !important;
        min-width: auto;

        &:hover,
        &:active {
          background: #fff;
        }

        ${media.desktop`
          background-color: ${({ theme }) => theme.secondary};
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
          height: 100%;
          box-shadow: 0;
          width: 150px !important;
        
        `}
        ${props =>
          props.isSticky &&
          media.tablet`
          width: 120px !important;
        `}
      `}
    
    ${props =>
      props.variant === 'roundButton' &&
      css`
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
        border: 1px solid #1a1a1a26;
        color: #000;
        padding: 0 30px 0 30px !important;
        border-radius: 28px !important;
        font-weight: bold;
        text-transform: none;

        &:hover,
        &:active {
          background: #fff;
        }
      `}

    ${props =>
      props.variant === 'search' &&
      css`
        background-color: transparent;
        border: 2px solid #fff;
        color: #fff;
        border-radius: 4px;
        font-weight: 600;
        padding: ${({ theme }) => theme.spacing(0.5)};
        width: auto;
        height: 46px;

        ${props =>
          props.square &&
          css`
            background-color: ${({ theme }) => theme.primary};
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0;
            border: 0;
            width: 100%;

            &:hover,
            &:active {
              background-color: ${({ theme }) => theme.primaryLight};
            }

            ${media.desktop`
              width: 46px;
            `}
          `}
      `}

    ${props =>
      props.variant === 'facebook' &&
      css`
        background-color: ${({ theme }) => theme.facebook};
        border: 2px solid transparent;
        color: #fff;
        border-radius: 4px;
        padding: ${({ theme }) => theme.spacing(0.5)};
        font-weight: 600;
        width: auto;
        height: 48px;

        &:hover {
          border: 2px solid ${({ theme }) => theme.facebook};
          background-color: transparent;
          color: ${({ theme }) => theme.facebook};
        }
      `}

  ${props =>
    props.variant === 'secondary' &&
    css`
      background-color: ${({ theme }) => theme.secondary};
      border: 2px solid ${({ theme }) => theme.secondary};
      color: #fff;
      border-radius: 4px;
      padding: ${({ theme }) => theme.spacing(0.5)};
      font-weight: 700;
      width: auto;

      &:hover {
        background-color: ${({ theme }) => theme.primary};
        border: 2px solid ${({ theme }) => theme.primary};
        color: #fff;
      }

      ${props =>
        props.isActive &&
        css`
          background-color: ${({ theme }) => theme.primaryLight};
          color: ${({ theme }) => theme.primary};
        `}
    `}

    ${props =>
      props.variant === 'promocode' &&
      css`
        background-color: transparent;
        border: 2px solid rgba(0, 0, 0, 0.6);
        color: rgba(0, 0, 0, 0.6);
        border-radius: 20px;
        padding: ${({ theme }) => theme.spacing(1)};
        padding-left: 30px;
        padding-right: 30px;
        font-weight: 700;
        width: auto;
        height: 50px;
        text-transform: none;
        margin-top: 13px;
        &:hover {
          background-color: ${({ theme }) => theme.primary};
          border: 2px solid ${({ theme }) => theme.primary};
          color: #fff;
        }

        ${props =>
          props.isActive &&
          css`
            background-color: ${({ theme }) => theme.primaryLight};
            color: white;
            border: 2px solid ${({ theme }) => theme.primaryLight};
          `}
      `}

    ${props =>
      props.variant === 'reverse' &&
      css`
        border: 2px solid #fff;
        background-color: transparent;
        color: #fff;
        border-radius: 4px;
        padding: ${({ theme }) => theme.spacing(0.5)};
        width: auto;
        height: 48px;
        font-weight: 600;
      `}


    ${props =>
      props.variant === 'tab' &&
      css`
        padding: 0;
        color: rgba(0, 0, 0, 0.4);
        font-weight: 700;

        &:hover {
          color: ${({ theme }) => theme.text};
        }

        ${props =>
          props.isActive &&
          css`
            color: ${({ theme }) => theme.text};
          `}
      `}


    ${props =>
      props.full &&
      css`
        width: 100%;
      `}
      ${props =>
        props.reservation &&
        css`
          background-color: #ffa24b;
          border-radius: 30px;
          color: #000;
          text-transform: none;
        `}

      svg {
        margin-right: ${({ theme }) => theme.spacing()};
      }

      ${props =>
        props.variant === 'danger' &&
        css`
          background-color: ${({ theme }) => theme.error};
          border-radius: 4px;
          padding: ${({ theme }) => theme.spacing(0.5)}
            ${({ theme }) => theme.spacing()};
          color: #fff;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
          font-weight: 600;
          height: 100%;

          &:hover,
          &:active {
            background-color: ${({ theme }) => theme.errorLight};
          }
        `}

        ${props =>
          props.variant === 'secondOutline' &&
          css`
            display: flex;
            outline: none;
            padding: 0.5em 1.5em;
            border: 0.16em solid ${({ theme }) => theme.primary};
            border-radius: 2em;
            text-decoration: none;
            font-weight: 400;
            color: ${({ theme }) => theme.primary};
            text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
            text-align: center;
            transition: all 0.2s;
            background-color: white;
            text-transform: none;

            &:hover {
              background-color: ${({ theme }) => theme.primary};
              color: white;
              font-weight: bold;
              cursor: pointer;
            }
          `}

          ${props =>
            props.variant === 'nav' &&
            css`
              display: inline;
              background-color: #ffa24b;
              text-transform: none;
              border-radius: 21px;
              margin-left: auto;
              color: #1a1a1a;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
              font-weight: bold;
              border: 1px solid #ffa24b;
              height: 48px;
              width: 48px;
            `}
`;

export const Paragraph = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  max-width: 50em;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing(-1)};
  margin-left: ${({ theme }) => theme.spacing(-1)};
  grid-template-columns: repeat(1, 1fr);

  ${media.tablet`
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
  `}

  ${props =>
    props.gutterVariant === 'medium' &&
    css`
      margin-top: ${({ theme }) => theme.spacing(-1.5)};
      margin-left: ${({ theme }) => theme.spacing(-1.5)};
      width: calc(100% + ${({ theme }) => theme.spacing(1.5)});

      > * {
        padding-top: ${({ theme }) => theme.spacing(1.5)}!important;
        padding-left: ${({ theme }) => theme.spacing(1.5)}!important;
      }
    `};

  ${props =>
    props.gutterVariant === 'large' &&
    css`
      margin-top: ${({ theme }) => theme.spacing(-2)};
      margin-left: ${({ theme }) => theme.spacing(-2)};
      width: calc(100% + ${({ theme }) => theme.spacing(2)});

      > * {
        padding-top: ${({ theme }) => theme.spacing(2)}!important;
        padding-left: ${({ theme }) => theme.spacing(2)}!important;
      }
    `};
    
  ${props =>
    props.variant === 'home' &&
    css`
      margin-left: 0px;
    `}

    ${props =>
      props.variant === 'article' &&
      css`
        display: grid;
        grid-column-gap: ${({ theme }) => theme.spacing(2)};
        grid-row-gap: ${({ theme }) => theme.spacing(3)};
        margin-top: 0;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;

        ${media.tablet`
          grid-template-columns: repeat(1, 1fr);
        `}

        ${media.medium`
          grid-template-columns: repeat(2, 1fr);
        `}

        ${media.desktop`
          grid-template-columns: repeat(3, 1fr);
        `}
      `}


  ${props =>
    props.variant === 'flags' &&
    css`
      display: grid;
      grid-column-gap: ${({ theme }) => theme.spacing(2)};
      grid-row-gap: ${({ theme }) => theme.spacing()};
      margin-top: 0;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      ${media.tablet`
        grid-template-columns: repeat(2, 1fr);
      `}

      ${media.medium`
        grid-template-columns: repeat(3, 1fr);
      `}

      ${media.desktop`
        grid-template-columns: 1fr 1fr;
      `}
    `}


  ${props =>
    props.variant === 'grid' &&
    css`
      display: grid;
      grid-column-gap: ${({ theme }) => theme.spacing(2)};
      grid-row-gap: ${({ theme }) => theme.spacing(2)};
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;

      ${media.tablet`
        grid-template-columns: repeat(2, 1fr);
        grid-template-row: 100%;
      `}

      ${media.medium`
        grid-template-columns: repeat(2, 1fr);
      `}

      ${media.desktop`
        grid-template-columns: repeat(${props =>
          props.visibilityMap ? 2 : 3}, 1fr);
      `}
      
    `}

  ${props =>
    props.variant === 'stats' &&
    css`
      display: grid;
      width: 100%;
      grid-column-gap: ${({ theme }) => theme.spacing()};
      grid-row-gap: ${({ theme }) => theme.spacing()};
      margin-top: 0;
      margin-left: 0;

      ${media.desktop`
        grid-template-columns: repeat(${props.columns}, 1fr);
      `}
    `}
    
`;

export const Cell = styled.div`
  flex: 1;
  padding-top: ${({ theme }) => theme.spacing()};
  padding-left: ${({ theme }) => theme.spacing()};
  min-width: 0;

  ${props =>
    props.sizes &&
    css`
      flex: 0 0 ${Math.floor(props.sizes.default * 10000) / 100}%;

      ${keys(props.sizes)
        .filter(key => key !== 'default')
        .map(
          breakpoint => css`
            ${media[breakpoint]`flex: 0 0 ${Math.floor(
              props.sizes[breakpoint] * 10000,
            ) / 100}%`};
          `,
        )};
    `};

  ${props =>
    props.min &&
    css`
      flex: 0;
      white-space: nowrap;
    `};
  ${props =>
    props.logement &&
    css`
      ${media.tablet`
        display:flex;
        align-items:center;
       `}
    `};
`;

export const MainNav = styled(List)`
  align-items: center;
  display: none;

  ${media.tablet`
    display: flex;
  `}
`;

export const NavLink = styled(Link)`
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme }) => theme.text};

  ${props =>
    props.isResponsiveMenu &&
    css`
      font-size: 16px;
      text-align: right;
      font-weight: 400;
      border: none;
      text-transform: none;
      padding: ${({ theme }) => theme.spacing()};
      color: inherit !important;
      text-shadow: none !important;
      background-color: ##1a1a1a26 !important;
      color: #fff !important;

      &:hover {
        background-color: ${({ theme }) => theme.primaryLight} !important;
      }
    `}
  ${props =>
    props.secondary &&
    css`
      font-size: 16px;
      text-align: right;
      font-weight: 400;
      border: none;
      text-transform: none;
      color: ${({ theme }) => theme.secondary};

      &:hover {
        color: ${({ theme }) => theme.secondaryLight} !important;
      }
    `}
    ${props =>
      props.variant === 'howItworks' &&
      css`
        color: #000000 !important;
        text-transform: none;
      `}
    ${props =>
      props.isBorderTop &&
      css`
        background: var(--white) 0% 0% no-repeat padding-box;
        margin-left: ${({ theme }) => theme.spacing(-0.5)};
        margin-right: ${({ theme }) => theme.spacing(-0.5)};
      `}

`;

export const MainNavItem = styled.li`
  font-size: 16px;
  font-weight: 800;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  transition: box-shadow 100ms ease;

  + * {
    margin-left: ${({ theme }) => theme.spacing(3)};
  }

  }

  ${props =>
    props.isAvatar &&
    css`
      &:hover,
      &:active {
        box-shadow: none;
      }
    `}

  ${props =>
    props.variant === 'hero' &&
    css`
      text-shadow: 0 0 13px rgba(0, 0, 0, 0.3);
      box-shadow: none;
      &:hover,
      &:active {
        text-shadow: none;
      }
    `}
`;

export const EmptyText = styled.div`
  color: ${({ theme }) => theme.textLighter};
  width: 100%;
  text-align: center;
  font-size: 14px;
`;

export const HeroHeader = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    url(${({ picture }) => picture});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: ${({ theme }) => theme.spacing(3)};
    text-align: center;
    padding: ${({ theme }) => theme.spacing(0.5)};
    color: ${({ theme }) => theme.white};
  }
  ${props =>
    props.hasBottomBorder &&
    css`
      &:after {
        content: ' ';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -65px;
        background-color: #fff;
        border-bottom: 1px solid ${({ theme }) => theme.separatorSoft};
      }
    `}
  ${media.tablet`
    h1 {
      ${({ theme }) => theme.fontStyles.h1Ultra};
    }
    h2 {
      font-size: ${({ theme }) => theme.spacing(5)};
    }
  `}
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;

  ${props =>
    props.reservation &&
    css`
      margin-top: 30px;
    `}
    
  ${props =>
    props.reverse &&
    css`
      flex-direction: column;
    `}
 ${media.medium`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const LeftContainer = styled.div`
  max-width: 800px;
  width: 100%;
  text-align: left;
  margin-top: ${({ theme }) => theme.spacing(2)};

  > * + * {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  ${props =>
    props.reservation &&
    css`
      max-width: unset;
      padding-left: 0%;
      ${media.medium`
      padding-left:10%;
    `}
    `}

  ${media.medium`
  text-align: left;
  margin-top:0;

    ${Buttons} {
      display: flex;
      justify-content: flex-end;
    }
  `}
`;

export const RightContainer = styled.div`
  max-width: 100%;
  width: 100%;
  margin-top: 0;
  ${media.medium`
    margin-left: ${({ theme }) => theme.spacing(2)};
    margin-top: ${({ theme }) => theme.spacing(-4)};
    max-width: 300px;

    ${props =>
      props.reservation &&
      css`
        max-width: 400px;
        margin-top: -15px;
      `}
    ${props =>
      props.hasNoTopMargin &&
      css`
        margin-top: 0;
      `}
  `};
`;

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(0.5)};

  ${props =>
    props.align === 'left' &&
    css`
      justify-content: center;
      ${media.desktop`
        justify-content left;
        padding-left: ${({ theme }) => theme.spacing(2)};
      `}
    `}

  ${props =>
    props.hasBottomBorder &&
    `
    &:after {
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;
      height: 2px;

      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    `}
`;

export const CarouselButton = styled(Button)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 100;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 4px;
  font-weight: 600;
  padding: ${({ theme }) => theme.spacing(0.5)};
  transition: color 150ms ease;
`;

export const Section = styled.div`
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing()};

  ${({ background }) =>
    background &&
    css`
      background-position: center center;
      background-size: cover;
      background: url("${({ background }) => background}");
    `};

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `}
`;

export const HomeSection = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
  ${props =>
    props.centered &&
    css`
      text-align: center;
    `}

  margin-left: 2.5%;
  margin-right: 2.5%;
  ${media.mobile`
    margin-left: 5%;
    margin-right: 5%;
  `};

  ${media.medium`
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  `};

  ${media.desktop`
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  `};
`;

export const Separator = styled.div`
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;
