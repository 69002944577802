import { get, post, put,putFile, FEELINGO_API } from './';

export const login = payload => post('/oauth/token', payload, FEELINGO_API);

export const register = payload =>
  post('/api/v1/registrations', payload, FEELINGO_API);

export const getMe = () => get('/api/v1/user/me', {}, FEELINGO_API);

export const getUserComments = () =>
  get('/api/v1/user/comments', [], FEELINGO_API);

export const updateMe = payload => put('/api/v1/user', payload, FEELINGO_API);

export const updateFile = payload => putFile('/api/v1/user', payload, FEELINGO_API);

export const forgotPassword = payload =>
  post('api/v1/user/password', payload, FEELINGO_API);

export const setPassword = payload =>
  put('/api/v1/user/password', payload, FEELINGO_API);
