import { useContext, useMemo, useEffect } from 'react';
import isFuture from 'date-fns/is_future';
import compareAsc from 'date-fns/compare_asc';
import differenceInDays from 'date-fns/difference_in_days';
import { useGTM } from '@tymate/react-gtm';
import AuthContext from 'contexts/auth';
import { parseBookingDate } from 'utils/date';

const NextBookingsTracking = () => {
  const { user } = useContext(AuthContext);
  const { pushDataLayer } = useGTM();

  const nextBookings = useMemo(
    () =>
      (user?.bookings || [])
        .filter(({ endAt }) => isFuture(parseBookingDate(endAt)))
        .sort((a, b) =>
          compareAsc(
            parseBookingDate(a?.startAt),
            parseBookingDate(b?.startAt),
          ),
        ),
    [user],
  );

  useEffect(() => {
    if (!nextBookings[0]) {
      return;
    }

    pushDataLayer({
      nextUserTripDays: differenceInDays(
        parseBookingDate(nextBookings[0].startAt),
        new Date(),
      ),
      nextUserTripLocation:
        nextBookings[0]?.koobData?.response?.data?.bookItem?.city,
    });
  }, [nextBookings, pushDataLayer]);

  return null;
};

export default NextBookingsTracking;
