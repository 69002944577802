const merge = require('lodash').merge;

function createClassOnDemand(ClassOrObject) {
  if (!ClassOrObject) return null;
  if (typeof ClassOrObject === 'function') return new ClassOrObject();
  return ClassOrObject;
}

const readFromBackend = (backend, language, namespace) =>
  new Promise(resolve => {
    backend.read(language, namespace, (err, data) => {
      if (err || !data) {
        return resolve({});
      }
      return resolve(data);
    });
  });

class MergeBackend {
  constructor(services, options = {}) {
    this.backends = [];
    this.type = 'backend';

    this.init(services, options);
  }

  init(services, options = {}, i18nextOptions) {
    this.services = services;
    this.options = options;

    this.options.backends &&
      this.options.backends.forEach((b, i) => {
        this.backends[i] = this.backends[i] || createClassOnDemand(b);
        this.backends[i].init(
          services,
          this.options.backendOptions[i],
          i18nextOptions,
        );
      });
  }

  async read(language, namespace, callback) {
    const datas = await Promise.all(
      this.backends.map(backend =>
        readFromBackend(backend, language, namespace),
      ),
    );

    const result = merge({}, ...datas);
    callback(null, result);
  }
}

MergeBackend.type = 'backend';

module.exports.default = MergeBackend;
