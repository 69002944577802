import { useContext } from 'react';
import { useLocation, useMount } from 'react-use';
import { navigate } from '@reach/router';
import AuthContext from 'contexts/auth';

export const useAuthentication = () => {
  const location = useLocation();
  const { isAuthenticated } = useContext(AuthContext);

  useMount(() => {
    if (!isAuthenticated) {
      navigate('/login', { replace: true, state: { nextUrl: location.href } });
    }
  });
};
