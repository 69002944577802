import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Field as RawField, Form as RawForm } from 'formik';
import AutosizeInput from 'react-input-autosize';
import { get } from 'lodash';
import { useField } from 'formik';
import { FormField, FormLabel, Input, Textarea } from 'ui/forms';
import { useTranslation } from 'react-i18next';
import { ButtonReset, media } from '../ui';
import { uniqueId } from 'lodash';
import SegmentedControl from 'components/SegmentedControl';

const Field = styled(RawField)`
  ${({ theme }) => theme.fontStyles.bodyLarger}
`;

const LabelAction = styled(ButtonReset)`
  color: ${({ theme }) => theme.textLight};
  ${({ theme }) => theme.fontStyles.body}
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
  text-align: left;

  &:hover {
    color: ${({ theme }) => theme.textLighter};
  }
`;

const NaturalInput = styled(AutosizeInput)`
  input {
    ${({ theme }) => theme.fontStyles.bodyLarger}
    border: none;
    outline: none;
    border-bottom: 2px dashed #999999;
    padding: 0;
    border-radius: 0;
    min-width: 150px;
    color: ${({ theme }) => theme.primary};

    ${props =>
        props.isNewGuest &&
        css`
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          min-width: 200px;
        `}
      ::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
  }
`;

const RedSpan = styled.span`
  color: red;
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.error};
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
`;

const RenderTextField = ({
  variant,
  placeholder,
  field,
  form,
  hasLostPasswordLink,
  label,
  inputValue,
  hasBigFont,
  needed,
  customWidth,
  ...props
}) => {
  const error = get(form.errors, field.name);
  const touched = get(form.touched, field.name);
  const hasError = Boolean(error && touched);
  const isNeeded = needed ? '*' : null;

  const { t } = useTranslation();
  return (
    <FormField variant={variant}>
      {label ? (
        <FormLabel>
          {`${label} `}
          <RedSpan>{isNeeded}</RedSpan>
        </FormLabel>
      ) : null}
      <Input
        variant={variant}
        type="text"
        value={inputValue}
        hasError={hasError}
        {...field}
        {...props}
        placeholder={placeholder}
      />
      {hasError && <ErrorText>{error}</ErrorText>}
      {hasLostPasswordLink && (
        <LabelAction type="button" onClick={hasLostPasswordLink}>
          {t('utils.forgottenPassword')}
        </LabelAction>
      )}
    </FormField>
  );
};

const NaturalTextFieldBase = ({
  variant,
  placeholder,
  field,
  form,
  hasLostPasswordLink,
  label,
  isNewGuest,
  ...props
}) => {
  const [value, setValue] = useState(field.value);
  const { t } = useTranslation();
  useEffect(() => setValue(field.value), [field.value]);

  return (
    <FormField variant={variant}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <NaturalInput
        injectStyles={false}
        isNewGuest={isNewGuest}
        {...props}
        value={value}
        onChange={e => setValue(e.target.value)}
        onBlur={e => form.setFieldValue(field.name, value)}
        placeholder={placeholder}
      />
      {hasLostPasswordLink && (
        <LabelAction type="button" onClick={hasLostPasswordLink}>
          {t('utils.forgottenPassword')}
        </LabelAction>
      )}
    </FormField>
  );
};

export const TextField = ({ validate, ...props }) => (
  <Field {...props} validate={validate} component={RenderTextField} />
);

export const NaturalTextField = props => (
  <Field {...props} component={NaturalTextFieldBase} />
);

export const Form = styled(RawForm)`

  ${({ isVertical }) =>
    !Boolean(isVertical) &&
    media.mobile`
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  `}


  > * {
    margin-top: 0;

    + * {
      margin-top: ${({ theme }) => theme.spacing()};
    }
  }

  ${props =>
    props.full &&
    css`
      width: 100%;
    `}
`;

const RenderTextareaField = ({ field, label, form, needed, ...props }) => {
  const isNeeded = needed ? '*' : null;
  const hasError = Boolean(form.errors[field.name]) && form.touched[field.name];
  const error = form.errors[field.name];

  return (
    <FormField>
      {Boolean(label) && (
        <FormLabel hasError={hasError}>
          {`${label} `}
          <RedSpan>{isNeeded}</RedSpan>
        </FormLabel>
      )}
      <Textarea {...field} {...props} hasError={hasError} />
      {hasError && <ErrorText>{error}</ErrorText>}
    </FormField>
  );
};

export const TextareaField = props => {
  const [id] = useState(uniqueId());

  return <Field {...props} id={id} component={RenderTextareaField} />;
};

export const SegmentedControlField = ({ options, ...props }) => {
  const [field, meta, { setValue }] = useField(props.name);
  return (
    <>
      <SegmentedControl
        multiple={false}
        values={field.value}
        onChange={value => {
          setValue(value);
        }}
        options={options}
      />
      {meta.error && meta.touched && <ErrorText>{meta.error}</ErrorText>}
    </>
  );
};
