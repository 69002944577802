import React, { useEffect, useState } from 'react';
import '../../styles/tailwind.css';
import Register from './Register';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import NewPassword from './NewPassword';
import { useTranslation } from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Auth(props) {
  const { t } = useTranslation();

  const tabs = [
    { name: t('modals.tabs.login'), component:
      <Login
        onLoginSuccess={() => props.onLoginSuccess() ?? {}}
        forgotPassword={() => forgotPassword()}
      />
    },
    { name: t('modals.tabs.register'), component:
        <Register onLoginSuccess={() => props.onLoginSuccess() ?? {}}/>
    },
    { name: 'FORGOT_PASSWORD', component: <ForgotPassword/>, hidden: true },
    { name: 'NEW_PASSWORD', component: <NewPassword/>, hidden: true },
  ];

  const [selected, setSelected] = useState(tabs[0]);

  const forgotPassword = () => {
    setSelected(tabs[2]);
  };

  useEffect(() => {
    if (props.isPasswordNew)
      setSelected(tabs[3]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tw px-10">
      <div className="pt-14 pb-2 text-center">
        <h1 className="text-xl font-bold">Feeling good?</h1>
      </div>

      <div>
        <nav className="-mb-px flex" aria-label="Tabs">
          {tabs.map((tab, index) => (
            (!tab.hidden) && (
              <button
                key={tab.name}
                onClick={() => setSelected(tabs[index])}
                className={classNames(
                  tab.name === selected.name
                    ? 'border-brand-orange text-brand-black'
                    : 'border-gray-100 text-brand-black opacity-60',
                  'w-1/2 py-4 px-1 text-center border-b-2 font-semibold'
                )}
              >
                {tab.name}
              </button>
            )
          ))}
        </nav>

        <div className="pt-10 pb-24">
          {selected.component}
        </div>
      </div>
    </div>
  )
}

export default Auth;
