import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import AuthContext from '../../contexts/auth';
import { get} from 'lodash';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { register } from '../../api/auth';
import * as Yup from 'yup';
import { ERRORS } from '../../utils';
import { CheckboxBlock, CheckboxText } from '../Footer';
import CheckboxField from '../../components/Checkbox';
import { useQueryParams } from '../../hooks';
import Input from '../UI/Input';
import Button from '../UI/Button';
import Separator from '../UI/Separator';
import FacebookLoginButton from './SocialAuth';

function Register({onLoginSuccess}) {
  const { t } = useTranslation();
  const { handleLogin } = useContext(AuthContext);
  const { invitation_code: inviteCode } = useQueryParams();

  const genderOptions = [
    { label: t('modals.register.genders.female'), value: 'female' },
    { label: t('modals.register.genders.male'), value: 'male' },
    { label: t('modals.register.genders.nb'), value: 'noBinary' },
  ];

  const registerFormSchema = Yup.object().shape({
    email: Yup.string()
      .email(ERRORS.EMAIL)
      .required(ERRORS.REQUIRED),
    firstName: Yup.string().required(ERRORS.REQUIRED),
    lastName: Yup.string().required(ERRORS.REQUIRED),
    password: Yup.string().required(ERRORS.REQUIRED),
    phoneNumber: Yup.string().optional(),
    address: Yup.object().shape({
      street: Yup.string().required(ERRORS.REQUIRED),
      postalCode: Yup.string().required(ERRORS.REQUIRED),
      city: Yup.string().required(ERRORS.REQUIRED),
    }),
  });

  const handleRegistration = async (values, { setErrors, setSubmitting }) => {
    try {
      await register({
        user: {
          gender: values.gender,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          birthday: format(
            new Date(
              values.year,
              values.month-1,
              values.day,
            ),
            'YYYY-MM-DD',
          ),
          inviteBy: inviteCode,
          phoneNumber: values.phoneNumber,
          address: `${values.address.street}, ${values.address.postalCode}, ${values.address.city}`,
        },
      });
      await handleLogin(values);
      await onLoginSuccess();
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      setErrors(get(err, 'response.data.errors.error', ''));
    }
  };

  return (
    <div>
      <FacebookLoginButton onLoginSuccess={onLoginSuccess}/>

      <div className="my-10" style={{display:'none'}}>
        <Separator>{t('modals.emailLogin.or')}</Separator>
      </div>

      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          password: '',
          month: '',
          year: '',
          day: '',
          gender: 'female',
          inviteBy: Boolean(inviteCode) ? inviteCode : '',
          address: {
            street: '',
            postalCode: '',
            city: '',
          },
          phoneNumber: '',
        }}
        onSubmit={handleRegistration}
        validateOnBlur={false}
        validationSchema={registerFormSchema}
      >
        {({values, isSubmitting}) => (
          <Form>
            <div className="mb-8">
              <Input
                as="select"
                label={t('modals.register.labels.gender')}
                name="gender"
                autoplaceholder
              >
                {genderOptions.map(o => (
                  <option value={o.value}>{o.label}</option>
                ))}
              </Input>
            </div>

            <div className="mb-8">
              <Input
                label={t('modals.register.labels.firstname')}
                name="firstName"
                autoplaceholder
              />
            </div>

            <div className="mb-8">
              <Input
                label={t('modals.register.labels.lastname')}
                name="lastName"
                autoplaceholder
              />
            </div>

            <div className="mb-8">
              <Input
                label={t('modals.register.labels.address')}
                name="address.street"
                autoplaceholder
              />
            </div>

            <div className="mb-8">
              <Input
                label={t('modals.register.labels.postalCode')}
                name="address.postalCode"
                autoplaceholder
              />
            </div>

            <div className="mb-8">
              <Input
                label={t('modals.register.labels.city')}
                name="address.city"
                autoplaceholder
              />
            </div>

            <div className="mb-8">
              <Input
                label={t('modals.register.labels.email')}
                name="email"
                type="email"
                autoplaceholder
              />
            </div>

            <div className="mb-8">
              <Input
                label={t('modals.register.labels.password')}
                name="password"
                type="password"
                autoplaceholder
              />
            </div>

            <div className="mb-8">
              <Input
                label={t('modals.register.labels.phoneNumber')}
                name="phoneNumber"
                type="tel"
                autoplaceholder
              />
            </div>

            <label className="mb-2 block text-sm sm:text-xs font-bold text-brand-black uppercase">{t('modals.register.labels.birthday')}</label>
            <div className="mb-8 grid grid-cols-3 gap-4">
              <Input
                label={t('modals.register.labels.day')}
                name="day"
                type="number"
                placeholder="DD"
              />

              <Input
                label={t('modals.register.labels.month')}
                name="month"
                type="number"
                placeholder="MM"
              />

              <Input
                label={t('modals.register.labels.year')}
                name="year"
                type="number"
                placeholder="YYYY"
              />
            </div>

            <input type="hidden" name="join" value="777029" />
            <CheckboxBlock style={{ margin: '16px 0' }}>
              <CheckboxField name="consent[]" value="1756-2-fr" />
              <CheckboxText>{t('footer.agreed')}</CheckboxText>
            </CheckboxBlock>

            <Button type="submit" full loading={isSubmitting} disabled={!values.consent}>
              {t('modals.register.buttons.email')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Register;
