import format from 'date-fns/format';
import parse from 'date-fns/parse';
import frLocale from 'date-fns/locale/fr';

export const formatDate = date =>
  format(date, 'DD/MM/YY  H[h]mm', { locale: frLocale });

export const oldFormatDate = date =>
  format(date, 'Le DD/MM/YY [à] H[h]mm', { locale: frLocale });

export const journeyFormatDate = date =>
  format(date, 'DD/MM/YY', { locale: frLocale });

export const journeyWithYearFormatDate = date =>
  format(date, 'DD/MM/YYYY', { locale: frLocale });

export const birthdateFormatDate = date =>
  format(date, 'DD MMMM YYYY', { locale: frLocale });

export const bookingFormatDate = date => format(date, 'YYYY-MM-DD');
export const parseBookingDate = date => parse(date, 'YYYY-MM-DD');
