import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import ReactModal from 'react-modal';
import { MdClose } from 'react-icons/md';
import { ButtonReset } from 'ui';

const defaultStyles = {
  overlay: {
    zIndex: 1000,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
};

const customStyles = {
  overlay: {
    zIndex: 1000,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

const StyledModal = styled(ReactModal)`
  position: absolute;
  background-color: #fff;
  overflow: 'visible';
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  padding: 0;
  max-height: calc(100vh - 32px);
  border-radius: 20px;
  box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  border: 0;
  width: 50em;
  
  max-width: calc(100vw - 32px);

  ${({ isSearchBar }) =>
    Boolean(isSearchBar) &&
    `
    width: auto;
    height: auto;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
  `}

  ${props =>
    props.variant === 'profile-traveler' &&
    css`
      max-width: 1600px;
      width: 90vw;
    `}

  ${props =>
    props.variant === 'natural' &&
    css`
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      max-width: 1200px;
    `}

  ${props =>
    props.variant === 'auth' &&
    css`
      border-radius: 25px;
      width: 40em;
      max-height: 80%;
      overflow-x: hidden;
    `}

  ${props =>
    props.scrollable &&
    css`
      overflow: scroll;
    `}
`;

export const CloseModalTriggerButton = styled(ButtonReset)`
  font-size: 24px;
  position: absolute;
  z-index: 2;
  top: 20px;
  right: 20px;
  color: #ffa24b;
  background-color: #fff1e3;
  border-radius: 30px;
  display: inline !important;
  height: 50px;
  width: 50px;
  padding-top: 7px;
  padding-bottom: 14px;
  padding-left: 14px;
  padding-right: 14px;

  > svg {
    padding-top: 4px;
  }
`;



class Modal extends Component {
  componentDidMount() {
    if (this.props.isOpen) {
      document.documentElement.style.overflowY = 'hidden';
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isOpen && this.props.isOpen) {
      document.documentElement.style.overflowY = 'hidden';
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      document.documentElement.style.overflowY = 'auto';
    }
  }

  componentWillUnmount() {
    document.documentElement.style.overflowY = 'auto';
  }

  render() {
    const {
      children,
      isOpen,
      onRequestClose,
      contentLabel,
      variant,
      scrollable,
      isNoClosable,
      isSearchBar,
      isPopup,
    } = this.props;
    return (
      <StyledModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel={contentLabel}
        variant={variant}
        scrollable={scrollable}
        style={variant === 'natural' ? defaultStyles : customStyles}
        isSearchBar={isSearchBar}
        className={variant === 'auth' ? 'no-scrollbar' : ''}
      >
        {!isNoClosable && !isPopup && (
          <CloseModalTriggerButton onClick={onRequestClose} children="&times;">
            <MdClose />
          </CloseModalTriggerButton>
        )}

        {children}
      </StyledModal>
    );
  }
}

export default Modal;