import { createContext } from 'react';

const AuthContext = createContext({
  user: null,
  isAuthenticated: false,
  handleAuth: step => {},
  handleLogin: (email, password) => {},
  handleRegister: params => {},
  handleFacebookLogin: () => {},
  handleCloseModal: () => {},
  handleReset: () => {},
});

export default AuthContext;
