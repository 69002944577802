import React, { useContext } from 'react';
import CountriesContext from '../contexts/country';
import PageContext from '../contexts/page';
import { useStaticQuery, graphql } from 'gatsby';

const CountriesProvider = ({ children }) => {
  const { language: pageLanguage } = useContext(PageContext);
  const { allCountry } = useStaticQuery(
    graphql`
      query {
        allCountry {
          nodes {
            id
            title
            slug
            alpha2
            language
            originalId
          }
        }
      }
    `,
  );

  const countries = allCountry.nodes.filter(
    ({ language }) => language === pageLanguage,
  );

  return (
    <CountriesContext.Provider value={{ countries }}>
      {children}
    </CountriesContext.Provider>
  );
};

export default CountriesProvider;
