import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Title, media } from 'ui';
import { Form } from 'components/Forms';
import Button from '../../components/UI/Button';
import { ERRORS } from '../../utils';
import CheckboxField from 'components/Checkbox';
import * as Yup from 'yup';
import AuthContext from 'contexts/auth';
import UtilsContext from 'contexts/util';
import { get } from 'lodash';
import { createContact } from 'api/contact';
import { useGTM } from '@tymate/react-gtm';
import { useTranslation } from 'react-i18next';
import Input from '../UI/Input';

const LightBlock = styled.div`
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)};

  > * {
    margin-top: 0;

    + * {
      margin-top: ${({ theme }) => theme.spacing(2)};
    }
  }

  ${media.desktop`
    padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) =>
    theme.spacing(6)};
  `}
`;


const ContactUs = () => {
  const { user } = useContext(AuthContext);
  const { onClose } = useContext(UtilsContext);
  const { pushDataLayer } = useGTM();
  const [isSent, setIsSent] = useState(false);
  const { t } = useTranslation();

  const contactFormSchema = Yup.object().shape({
    email: Yup.string()
      .email(ERRORS.EMAIL)
      .required(ERRORS.REQUIRED),
    firstName: Yup.string().required(ERRORS.REQUIRED),
    lastName: Yup.string().required(ERRORS.REQUIRED),
    object: Yup.string().required(ERRORS.REQUIRED),
    message: Yup.string().required(ERRORS.REQUIRED),
    consent: Yup.boolean()
      .required()
      .oneOf([true], ERRORS.REQUIRED),
  });

  const handleSubmit = async values => {
    try {
      await createContact(values);
      pushDataLayer({ event: 'submitContact' });
      setIsSent(true);

      setTimeout(() => onClose(), 10000);
    } catch (err) {
      setIsSent(false);
    }
  };

  return (
    <LightBlock>
      <Title>{t('contact.us')}</Title>
      {Boolean(isSent) ? (
        <p className="text-green-600 font-medium text-center">{t('contact.success')}</p>
      ) : (
        <Formik
          validationSchema={contactFormSchema}
          initialValues={{
            email: get(user, 'email', ''),
            firstName: '',
            lastName: '',
            object: '',
            message: '',
            consent: false,
          }}
          onSubmit={handleSubmit}
        >
          {({isSubmitting}) => (
            <Form>
              <div className="py-2">
                <Input
                  label={t('utils.form.firstname')}
                  name="firstName"
                  autoplaceholder
                  required
                />
              </div>

              <div className="py-2">
                <Input
                  label={t('utils.form.lastname')}
                  name="lastName"
                  autoplaceholder
                  required
                />
              </div>

              <div className="py-2">
                <Input
                  label={t('utils.form.email')}
                  name="email"
                  autoplaceholder
                  required
                />
              </div>

              <div className="py-2">
                <Input
                  label={t('utils.form.object')}
                  name="object"
                  autoplaceholder
                  required
                />
              </div>

              <div className="py-2">
                <Input
                  as="textarea"
                  rows="4"
                  label={t('utils.form.message')}
                  name="message"
                  required
                />
              </div>

              <div className="py-4">
                <CheckboxField
                  label="En soumettant ce formulaire, j’accepte que les informations saisies soient utilisées, exploitées, traitées pour permettre de me recontacter, de m’informer sur les produits, dans le cadre de la relation commerciale qui découle de cette demande d’information."
                  name="consent"
                />
              </div>

              <Button type="submit" full loading={isSubmitting}>
                {t('utils.form.send')}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </LightBlock>
  );
};

export default ContactUs;
