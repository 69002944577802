import { get, post, put, destroy, FEELINGO_API } from './';

export const getProfiles = () =>
  get('/api/v1/user/profiles?includes=choices', null, FEELINGO_API);

export const updateProfile = (id, payload) =>
  put(`/api/v1/user/profiles/${id}`, payload, FEELINGO_API);

export const createProfile = payload =>
  post('/api/v1/user/profiles', payload, FEELINGO_API);

export const deleteProfile = id => destroy(`/api/v1/user/profiles/${id}`);

export const getProfileCategories = () =>
  get(
    '/api/v1/categories',
    { includes: 'choices,choices.translations,choices.picture', lang: 'fr' },
    FEELINGO_API,
  );
export const getProfileCategoriesWithPhrases = () =>
  get(
    '/api/v1/categories',
    { includes: 'phrases,phrases.translations', lang: 'fr' },
    FEELINGO_API,
  );

export const getMoneyPots = ({ language }) =>
  get('api/v1/user/money_pot', { lang: language });

export const getEarnings = ([language]) =>
  get('api/v1/user/earnings', { lang: language });

export const getBookings = ({ includes, language }) =>
  get('/api/v1/user/bookings', { includes, lang: language });
