import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ERRORS } from '../../utils';
import { setPassword } from '../../api/auth';
import { camelizeKeys } from 'humps';
import { useQueryParams } from '../../hooks';
import Button from '../UI/Button';
import Input from '../UI/Input';

function NewPassword() {
  const { t } = useTranslation();
  const { resetToken } = camelizeKeys(useQueryParams());

  const SCHEMA = Yup.object().shape({
    password: Yup.string().required(ERRORS.REQUIRED),
    confirmation: Yup.string().test(
      'match',
      t('modals.newPassword.errors.mismatch'),
      function(confirmation) {
        return confirmation === this.parent.password;
      },
    ),
  });

  const handleSetPassword = async (values, { setErrors }) => {
    try {
      await setPassword({
        user: {
          confirmation_token: resetToken,
          password: values.password,
        },
      });
      window.location.href = '/';
    } catch (e) {
      setErrors('invalidToken', true);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ password: '', confirmation: '' }}
        validationSchema={SCHEMA}
        validateOnBlur={false}
        onSubmit={handleSetPassword}
      >
        {({isSubmitting, errors}) => (
          <Form>
            <div className="mb-8">
              <Input
                label={t('modals.newPassword.inputs.newPassword')}
                name="password"
                type="password"
              />
            </div>

            <div className="mb-8">
              <Input
                label={t('modals.newPassword.inputs.confirmation')}
                name="confirmation"
                type="password"
              />
            </div>

            {errors.invalidToken && (
              <p className="mt-2 text-center text-red-600">
                {t('modals.newPassword.errors.invalidToken')}
              </p>
            )}

            <Button type="submit" full loading={isSubmitting}>
              {t('modals.newPassword.button')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewPassword;
