import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { forgotPassword } from '../../api/auth';
import Input from '../UI/Input';
import Button from '../UI/Button';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [hasSucceeded, setHasSucceeded] = useState(false);

  const handleSubmit = async (values, { setFieldError }) => {
    try {
      await forgotPassword({ email: values.email });
      setHasSucceeded(true);
    } catch (e) {
      setFieldError('email', t('modals.forgotPassword.errors.invalid'));
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          email: ''
        }}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({isSubmitting}) => (
          <Form>
            <div className="mb-10">
              <Input
                label={t('modals.forgotPassword.labels.email')}
                name="email"
                type="email"
                placeholder={t('modals.forgotPassword.labels.email')}
              />
            </div>

            <Button type="submit" full loading={isSubmitting}>
              {t('modals.forgotPassword.buttons.password')}
            </Button>
          </Form>
        )}
      </Formik>
      {hasSucceeded &&
        <p className="mt-6 text-center text-green-600">{t('modals.forgotPassword.link')}</p>
      }
    </div>
  );
};

export default ForgotPassword;
