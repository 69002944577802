import React, { useEffect, useReducer } from 'react';
import Modal from '../components/Modal';
import { decamelize } from 'humps';
import { get } from 'lodash';
import AuthContext from '../contexts/auth';
import { loadAuth, saveAuth, clearAuth } from '../api/index';
import { login, getMe } from '../api/auth';
import { navigate } from '@reach/router';
import AuthForm from '../components/AuthForm';

const authReducer = (state, action) => {
  const auth = loadAuth();
  switch (action.type) {
    case 'FETCH_AUTH':
    case 'RELOAD_AUTH':
      return {
        ...state,
        isAuthenticated: Boolean(auth),
        auth,
        user: null,
      };
    case 'SET_USER':
      return {
        ...state,
        isAuthenticated: Boolean(auth),
        auth,
        user: action.user,
      };
    case 'OPEN_MODAL':
      return {
        ...state,
        isModalOpen: true,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        isModalOpen: false,
      };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const initialAuth = loadAuth();
  const [state, dispatch] = useReducer(authReducer, {
    isAuthenticated: Boolean(initialAuth),
    auth: initialAuth,
  });
  const { user, isModalOpen, isAuthenticated } = state;

  const handleGetMe = async () => {
    const { user } = await getMe();
    dispatch({ type: 'SET_USER', user });
    dispatch({ type: 'CLOSE_MODAL' });
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleGetMe();
    }
  }, [isAuthenticated]);

  const handleAuth = () => {
    dispatch({ type: 'OPEN_MODAL' });
  };

  const handleCloseAuth = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const handleLogin = async values => {
    const result = await login({
      grantType: 'password',
      email: values?.email,
      password: values?.password,
    });
    saveAuth(result);
    dispatch({ type: 'RELOAD_AUTH' });
    handleCloseAuth();
    handleGetMe();
  };

  const loginStatus = () =>
    new Promise(resolve =>
      window.FB.getLoginStatus(response => resolve(response)),
    );

  const fbLogin = (scopes = []) =>
    new Promise(resolve =>
      window.FB.login(response => resolve(response), {
        scope: scopes.map(scope => decamelize(scope)).join(','),
      }),
    );

  const getFacebookAuthInfo = async () => {
    let { authResponse, status } = await loginStatus();
    if (status === 'connected') {
      return { authResponse, status };
    }
    return await fbLogin(['email', 'userBirthday']);
  };

  const handleFacebookLogin = async () => {
    const { authResponse } = await getFacebookAuthInfo();
    const fbAccessToken = get(authResponse, 'accessToken');
    if (!fbAccessToken) {
      return;
    }
    const response = await login({
      grantType: 'assertion',
      provider: 'facebook',
      assertion: fbAccessToken,
    });
    saveAuth(response);
    dispatch({ type: 'RELOAD_AUTH' });
    handleGetMe();
    handleCloseAuth();
  };

  const handleCloseModal = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const handleReset = () => {
    clearAuth();
    dispatch({ type: 'RELOAD_AUTH' });
    navigate('/');
  };

  const contextData = {
    user,
    isAuthenticated,
    handleAuth: handleAuth,
    handleReset: handleReset,
    handleCloseAuth: handleCloseAuth,
    handleLogin: handleLogin,
    handleFacebookLogin: handleFacebookLogin,
    handleGetMe: handleGetMe,
  };

  return (
    <AuthContext.Provider value={contextData}>
      <>
        {isModalOpen && (
          <Modal isOpen scrollable={true} onRequestClose={handleCloseModal} variant="auth">
            <AuthForm />
          </Modal>
        )}
        {children(contextData)}
      </>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
