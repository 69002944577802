import React from 'react';
import SearchBar from '../SearchBar';
import ContactUs from './ContactUs';

export const CONTACT_US = 'util.contactUs';
export const VERTICAL_SEARCH = 'util.search';

const UtilModal = ({ kind }) => {
  switch (kind) {
    case CONTACT_US:
      return <ContactUs />;
    case VERTICAL_SEARCH:
      return <SearchBar isModal variant="home" isVertical />;
    default:
      return null;
  }
};

export default UtilModal;
