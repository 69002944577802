import React, { useContext, useRef } from 'react';
import AuthContext from 'contexts/auth';
import styled, { css } from 'styled-components';
import { Formik } from 'formik';
import { pickBy } from 'lodash';
import { Form } from 'components/Forms';
import { DateField } from 'components/DateField';
import LocationField from 'components/LocationField';
import 'moment/locale/fr';
import format from 'date-fns/format';
import { Button, media } from 'ui';
import { MdSearch } from 'react-icons/md';
import { navigate } from '@reach/router';
import { formatSearch } from 'utils';
import { useAsync } from 'react-async';
import { getProfiles } from 'api/profiles';
import SelectField from 'components/Select';
import ToggleSwitch from 'components/ToggleSwitch';
import { useTranslation } from 'react-i18next';
import iconsRateEcology0 from '../assets/images/iconsRateEcology0.svg';
import iconsRateEcology1 from '../assets/images/iconsRateEcology1.svg';
import iconsRateEcology2 from '../assets/images/iconsRateEcology2.svg';
import iconsRateEcology3 from '../assets/images/iconsRateEcology3.svg';
import iconsRateEcology4 from '../assets/images/iconsRateEcology4.svg';
import UtilsContext from 'contexts/util';
import SearchAges from 'components/SearchAges';

export const SearchWrapper = styled.div`
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing()};
  min-width: 200px;
  max-width: 375px;

  ${({ isVertical }) =>
  !Boolean(isVertical) &&
  media.desktop`
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  `}

  > * + * {
    margin-top: ${({ theme }) => theme.spacing()};
    ${({ isVertical }) =>
  !Boolean(isVertical) &&
  media.desktop`
      margin-left: ${({ theme }) => theme.spacing(0.5)};
      margin-top: 0;
    `}
  }

  ${({ isVertical }) =>
  !Boolean(isVertical) &&
  media.desktop`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(0.5)} 0;
    box-shadow: none;
    background-image: none;
  `}

  ${props =>
  props.variant === 'home' &&
  css`
      border-radius: 4px;
      padding: ${({ theme }) => theme.spacing()};
      min-width: 200px;
      max-width: 375px;
      background-image: none;
      box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
      background-color: ${({ isVertical }) =>
    Boolean(isVertical) ? '#fff' : 'rgba(0, 0, 0, 0.3)'};

      > * {
        margin-left: 0;
        height: 65px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
      }

      ${media.desktop`
        padding: ${({ theme }) => theme.spacing(2)};
      `}

      ${({ isVertical }) =>
    !Boolean(isVertical) &&
    media.desktop`
        box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
        padding: 0;
        flex-direction: 'row';
        background-image: none;
        justify-content: center;


        > * {
          border: 0;
          border-radius: 4px 0 0 4px;
          height: 80px;


          + * {
            border-left: 1px solid #c2c2c2;
            border-radius: 0;
          }
        }

        button:last-child {
          border-radius: 0 4px 4px 0;

        }

        button:first-child {
          border-radius: 4px 0 0 4px;

        }

      `}

      ${({ isModal }) =>
    isModal &&
    css`
          padding: ${({ theme }) => theme.spacing(4)}
            ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(2)};
          ${media.tablet`
            padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) =>
      theme.spacing(2)} ${({ theme }) => theme.spacing(2)};
          `}
        `}
    `}
`;

export const ButtonWrapper = styled.div`
  color: #fff;
  svg {
    display: none;
    margin: 0;
  }

  p {
    display: block;
  }

  ${({ isVertical }) =>
  !Boolean(isVertical) &&
  media.desktop`
    display: flex;
    align-items: center;
    justify-content: center;

      svg {
        display: block;
        font-size: 24px;
      }

      p {
        display: none;
      }

  `};

  ${props =>
  props.square &&
  css`
      ${media.desktop`
        width: 80px;
      `}
    `}
`;

const ToggleModule = styled.div`
  position: absolute;
  right: 0;
  margin-right: ${({ theme }) => theme.spacing(2)};
  display: none;
  align-items: center;

  ${media.desktop`
    display: flex;
  `};
`;

const MapLabel = styled.div`
  color: ${({ theme }) => theme.text};
  margin-right: ${({ theme }) => theme.spacing()};
`;

const SearchBar = ({
                     id,
                     variant,
                     initialValues,
                     handleVisibilityMap,
                     visibilityMap,
                     showToggleButton,
                     isVertical,
                     isModal,
                   }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { onClose } = useContext(UtilsContext);
  const { data: { profiles } = {} } = useAsync({
    promiseFn: getProfiles,
    watch: user,
  });

  const profilesOption = (profiles || []).map(({ displayName, id }) => ({
    label: displayName,
    value: id,
  }));

  const sustainabilitiesOption = [
    {
      label: t('searchBar.sustainable.options.option0'),
      icon: iconsRateEcology0,
      value: -1,
    },
    {
      label: t('searchBar.sustainable.options.option1'),
      icon: iconsRateEcology1,
      value: 1,
    },
    {
      label: t('searchBar.sustainable.options.option2'),
      icon: iconsRateEcology2,
      value: 2,
    },
    {
      label: t('searchBar.sustainable.options.option3'),
      icon: iconsRateEcology3,
      value: 3,
    },
    {
      label: t('searchBar.sustainable.options.option4'),
      icon: iconsRateEcology4,
      value: 4,
    },
  ];

  const showProfileData = initialValues && initialValues.showProfileData;
  const handleSearch = values => {
    const { dateRange, ...rest } = values;

    const search = formatSearch({
      ...rest,
      showProfileData:showProfileData,
      guests: 2,
      ...(dateRange[0] &&
        dateRange[1] && {
          startDate: format(dateRange[0], 'YYYY-MM-DD'),
          endDate: format(dateRange[1], 'YYYY-MM-DD'),
        }),
    });

    navigate(`/search?${search}`);

    onClose();
  };

  const searchBarRef = useRef();

  const showSustainabilityField = values =>
    !Boolean(user) || (!Boolean(values.profile) && Boolean(user));

  return (
    <div ref={searchBarRef}>
      <Formik
        enableReinitialize
        initialValues={
          initialValues
            ? pickBy({
              ...initialValues,
              dateRange: [initialValues?.startDate, initialValues?.endDate],
              guests: Number(initialValues?.guests),
              profile:
                initialValues?.profile && Number(initialValues?.profile),
              sustainability:
                initialValues?.sustainability &&
                Number(initialValues?.sustainability),
              ageCategories: {
                adults: initialValues?.ageCategories?.adults ?? 2,
                children: initialValues?.ageCategories?.children ?? 0,
                infants: initialValues?.ageCategories?.infants ?? 0,
              },
            })
            : {
              ageCategories: {
                adults: 2,
                children: 0,
              },
            }
        }
      >
        {({ values }) => (
          <Form
            id={id}
            onSubmit={e => {
              e.preventDefault();
              handleSearch(values);
            }}
          >
            <SearchWrapper
              isVertical={isVertical}
              isModal={isModal}
              variant={variant}
            >
              {Boolean(user) && showProfileData!=="false" && (
                <SelectField
                  name="profile"
                  isVertical={isVertical}
                  variant={variant}
                  placeholder={t('searchBar.profile')}
                  options={
                    profilesOption.length > 0
                      ? [
                        { label: t('searchBar.unselected'), value: '' },
                        ...profilesOption,
                      ]
                      : [
                        {
                          label: (
                            <Button
                              variant="secondary"
                              onClick={e => {
                                e.preventDefault();
                                navigate('/profile-walkthrough');
                              }}
                            >
                              {t('profiles.account.create')}
                            </Button>
                          ),
                        },
                      ]
                  }
                  type="profile"
                />
              )}
              <LocationField
                name="location"
                variant={variant}
                full={variant === 'home'}
                placeholder={t('search.geo')}
                type="location"
                isVertical={isVertical}
              />
              <DateField
                variant={variant}
                name="dateRange"
                isOutsideRange={() => false}
                startDatePlaceholder={t('searchBar.dates.startDate')}
                endDatePlaceholder={t('searchBar.dates.endDate')}
                isVertical={isVertical}
              />
              <SearchAges name="ageCategories" variant={variant} />
              {showSustainabilityField(values) && (
                <SelectField
                  name="sustainability"
                  variant={variant}
                  placeholder={t('searchBar.sustainable.placeholder')}
                  options={[
                    { label: t('searchBar.unselected'), value: '' },
                    ...sustainabilitiesOption,
                  ]}
                  type="sustainability"
                />
              )}
              <Button
                square
                style={isVertical && { width: '100%' }}
                variant={variant}
              >
                <ButtonWrapper isVertical={isVertical} square>
                  <MdSearch />
                  <p style={{ margin: 0 }}>{t('utils.research')}</p>
                </ButtonWrapper>
              </Button>
              {showToggleButton && (
                <ToggleModule>
                  <MapLabel>
                    {visibilityMap ? t('searchBar.hide') : t('searchBar.show')}
                  </MapLabel>
                  <ToggleSwitch
                    checked={visibilityMap}
                    onChange={handleVisibilityMap}
                  />
                </ToggleModule>
              )}
            </SearchWrapper>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SearchBar;
