import styled, { css } from 'styled-components';
import { ButtonReset } from 'ui';

export const Trigger = styled(ButtonReset)`
  background-color: #fff;
  outline: none;
  padding: ${({ theme }) => theme.spacing(0.75)};
  color: #000;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.separator};
  width: 100%;


  ${props =>
    props.variant === 'natural' &&
    css`
      padding-left: 0;
      border: 0;
      border-radius: 0;
      width: auto;
      height: auto;
      align-items: 0;
    `}

  ${props =>
    props.variant === 'search' &&
    css`
      padding: 0 ${({ theme }) => theme.spacing(0.5)};
      height: 100%;
      icon {
        font-size: 24px;
      }
    `}
  ${props =>
    props.variant === 'homeSearch' &&
    css`
        border-radius: 0;
        height: 100%;
        padding: 0 !important;
        border: none;
        ::placeholder {
          text-align:left;
        }
    `}
  ${props => 
    props.variant === 'home' &&
        css`
        border-radius: 0;
        height: 100%;
        padding: 0 ${({ theme }) => theme.spacing(1.5)};
        border: none;
      `}

      ${props =>
        props.variant === 'hotel' &&
        css`
          border-radius: 0;
          padding: 0 ${({ theme }) => theme.spacing(1.5)};
          border: none;
        `}

    ${props => props.variant === 'fakenatural' && css``}
    
    ${props =>
      props.variant === 'borderless' &&
      css`
            border: none;
    `}

    ${props =>
      props.variant === 'article' &&
      css`
      height:40px !important;
      border: 1px solid #1A1A1A26;
      border-radius: 28px !important;
      background-color: #fff;
    `}


    ${props =>
      props.variant==='menu' &&
      css` 
        height:60px !important;
        padding: 30px;
        border: 1px solid #1A1A1A26;
        border-radius: 28px !important;
        background-color: #fff;
    `}
`;

export const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;

  ${props =>
    props.variant === 'natural' &&
    css`
      width: auto;
      height: auto;
    `}

  ${props =>
    props.variant === 'search' &&
    css`
      margin-top: 0;
      height: 48px;
      width: auto;
    `}
      
  ${props =>
    props.variant!==undefined && props.variant.indexOf('home')===0 &&
    css`
      margin-top: 0;
      height: 100%;
    `}
`;

export const PlaceHolderLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  color: ${({ theme }) => theme.textLighter};

  ${props =>
  props.variant!==undefined && props.variant.indexOf('home')===0 &&
    css`
      font-size: 14px;
      font-weight: 600;
      white-space: nowrap;
      color: ${({ theme }) => theme.textLighter};
      text-transform: uppercase;
    `}
    
  ${props =>
  props.variant === 'homeSearch' &&
  css`
      font-size: 14px;
      font-weight: 600;
      white-space: nowrap;
      color: ${({ theme }) => theme.textLighter};
      text-transform: capitalize;
    `}

    ${props =>
      props.variant==='menu' &&
      css` 
        color: #000;
    `}

  ${props =>
    props.variant === 'natural' &&
    css`
      font-size: 18px;
      font-weight: 400;
    `}
    ${props =>
      props.variant === 'article' &&
      css`
        color:#000 !important;
        font: normal normal bold 14px/18px Muli;
      `}

  ${props =>
    props.variant === 'search' &&
    css`
      font-size: 14px;
      font-weight: 600;
      white-space: nowrap;
      padding-left: ${({ theme }) => theme.spacing(0.5)};
      padding-right: ${({ theme }) => theme.spacing(0.5)};
      text-transform: uppercase;
    `}
`;

export const ValueWrapper = styled.div`
  color: ${({ theme }) => theme.text};
  font-weight: 400;
  align-items: center;
  display: flex;

  span:first-child {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }

  ${props =>
    props.variant!==undefined && props.variant.indexOf('home')===0 &&
    css`
      ${props =>
        props.isEmpty &&
        css`
        color: ${({ theme }) => theme.textLighter} !important;
        font-weight: 500 !important;
      `}
      font-weight: 600;
    `}
    
  ${props =>
  props.variant!==undefined && props.variant==='article' &&
  css`
    font-weight: bold;
  `}
`;

export const Icon = styled.div`
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  width: auto !important;

`;
