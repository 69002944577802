import React from 'react';

const IcInstagram = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Groupe_35" data-name="Groupe 35" transform="translate(-1146 -3930)">
      <g
        id="Rectangle_22"
        data-name="Rectangle 22"
        transform="translate(1146 3930)"
        fill="#fff"
        stroke="#707070"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      </g>
      <path
        id="Icon_awesome-instagram"
        data-name="Icon awesome-instagram"
        d="M10.5,7.355a5.386,5.386,0,1,0,5.386,5.386A5.377,5.377,0,0,0,10.5,7.355Zm0,8.887a3.5,3.5,0,1,1,3.5-3.5A3.508,3.508,0,0,1,10.5,16.243Zm6.862-9.108a1.256,1.256,0,1,1-1.256-1.256A1.253,1.253,0,0,1,17.365,7.135ZM20.933,8.41a6.217,6.217,0,0,0-1.7-4.4,6.258,6.258,0,0,0-4.4-1.7c-1.734-.1-6.933-.1-8.667,0A6.249,6.249,0,0,0,1.765,4a6.237,6.237,0,0,0-1.7,4.4c-.1,1.734-.1,6.933,0,8.667a6.217,6.217,0,0,0,1.7,4.4,6.266,6.266,0,0,0,4.4,1.7c1.734.1,6.933.1,8.667,0a6.217,6.217,0,0,0,4.4-1.7,6.258,6.258,0,0,0,1.7-4.4c.1-1.734.1-6.928,0-8.662ZM18.692,18.933a3.545,3.545,0,0,1-2,2c-1.383.548-4.664.422-6.192.422s-4.814.122-6.192-.422a3.545,3.545,0,0,1-2-2c-.548-1.383-.422-4.664-.422-6.192s-.122-4.814.422-6.192a3.545,3.545,0,0,1,2-2C5.694,4,8.975,4.13,10.5,4.13s4.814-.122,6.192.422a3.545,3.545,0,0,1,2,2c.548,1.383.422,4.664.422,6.192S19.24,17.555,18.692,18.933Z"
        transform="translate(1147.339 3929.259)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default IcInstagram;
