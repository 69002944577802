import axios from 'axios';
import qs from 'qs';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { API_URL } from 'constants';
import { navigate } from '@reach/router';

export const FEELINGO_API = 'api.feelingo';
export const PHH_API = 'api.phh';

export const api = axios.create({
  baseURL: API_URL,
});

export const loadAuth = () => {
  try {
    if (!localStorage.user) {
      return null;
    }
    return JSON.parse(localStorage.user);
  } catch (e) {
    clearAuth();
    return null;
  }
};

export const saveAuth = auth => (localStorage.user = JSON.stringify(auth));

export const clearAuth = () => localStorage.removeItem('user');

api.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    return qs.stringify(decamelizeKeys(params), { arrayFormat: 'brackets' });
  };

  if (loadAuth()) {
    const { accessToken } = loadAuth();
    if(!accessToken){
        navigate('/login');
    }
    config = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }

  return config;
});

const refreshToken = async () => {
  if (loadAuth()) {
    const { refreshToken } = loadAuth();
    const { data } = await post(
      '/oauth/token',
      {
        grantType: 'refresh_token',
        refreshToken,
      },
      FEELINGO_API,
    );
    if (data) {
      saveAuth(camelizeKeys(data));
    } else {
      clearAuth();
    }
  }
};

api.interceptors.response.use(
  response => response,
  async error => {
    if (error.response?.status === 401 && loadAuth()) {
      await refreshToken();
      return api.request(error.config);
    }
    throw error;
  },
);

export const get = (path, params, service = FEELINGO_API) =>
  api
    .get(path, {
      params,
      service,
      headers: {
        ...(params && params.lang ? { lang: params.lang } : {}),
      },
    })
    .then(response => camelizeKeys(response.data));
    
export const getSearch = (path, params, service = FEELINGO_API) =>
  api
    .get(path, {
      params,
      service,
      headers: {
        ...(params && params.lang ? { lang: params.lang } : {}),
      },
    })
    .then(response => ({
      body: camelizeKeys(response.data),
    }));

export const getPaginated = (path, params, service = FEELINGO_API) =>
  api
    .get(path, {
      params,
      service,
      headers: {
        ...(params && params.lang ? { lang: params.lang } : {}),
      },
    })
    .then(response => ({
      body: camelizeKeys(response.data),
      pagination: {
        currentPage: Number(response.headers['pagination-current-page']),
        per: Number(response.headers['pagination-per']),
        totalCount: Number(response.headers['pagination-total-count']),
        totalPages: Number(response.headers['pagination-total-pages']),
      },
    }));

export const post = (path, payload, service = FEELINGO_API) =>
  api
    .post(path, decamelizeKeys(payload), { service })
    .then(response => camelizeKeys(response.data));

export const put = (path, payload, service = FEELINGO_API) =>
  api
    .put(path, decamelizeKeys(payload), { service })
    .then(response => camelizeKeys(response.data));

export const putFile = (path, payload,  service = FEELINGO_API) =>
    api.put(path, payload, {service});

export const destroy = (path, service = FEELINGO_API) =>
  api.delete(path, { service }).then(response => camelizeKeys(response.data));
