import React from 'react';

function Separator(props) {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      {props.children && (
        <div className="relative flex justify-center">
          <span className="px-2 bg-white text-sm text-gray-500">{props.children}</span>
        </div>
      )}
    </div>
  );
}

export default Separator;
