import React, { useContext, useState } from 'react';
import { useAsync } from 'react-async';
import { useDebounce } from 'react-use';
import { get } from 'lodash';
import { useField, useFormikContext } from 'formik';
import { search } from 'api/search';
import { Select } from 'components/Select';
import PageContext from 'contexts/page';

const LocationField = ({ variant, ...props }) => {
  const [field] = useField(props);
  const formik = useFormikContext();
  const { language } = useContext(PageContext);
  const { placeholder } = props;
  const { data: results = [], run } = useAsync({
    deferFn: ([query]) => search({ query, language: language }),
  });

  const [query, setQuery] = useState();

  useDebounce(
    () => {
      run(query);
    },
    100,
    [query],
  );

  const handleChange = async item => {
    if (item.kind === 'Hotel') {
      formik.setFieldValue(props.name, [item.title]);
      formik.setFieldValue('countryId', undefined);
      formik.setFieldValue('cityId', undefined);
      formik.setFieldValue('regionId', undefined);
    } else if (item.kind === 'Country') {
      formik.setFieldValue('countryId', item.id);
      formik.setFieldValue('cityId', undefined);
      formik.setFieldValue('regionId', undefined);
      formik.setFieldValue(props.name, [item.title]);
    }else if (item.kind === 'Region') {
      formik.setFieldValue('regionId', item.id);
      formik.setFieldValue('cityId', undefined);
      formik.setFieldValue('countryId', undefined);
      formik.setFieldValue(props.name, [item.title]);
    } 
    else {
      formik.setFieldValue('countryId', undefined);
      formik.setFieldValue('regionId', undefined);
      formik.setFieldValue('cityId', item.id);
      
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        { address: item.title, region: item.alpha2 },
        results => {
          const bounds = get(results, '0.geometry.bounds');
          const bbox = [
            bounds?.getNorthEast().lat(),
            bounds?.getNorthEast().lng(),
            bounds?.getSouthWest().lat(),
            bounds?.getSouthWest().lng(),
          ];
          formik.setFieldValue(props.name, [item.title, bbox.join(',')]);
        },
      );
    }
  };

  const [initialInputValue] = field.value || [];

  return (
    <Select
      hasInput
      options={results.map(result => ({
        ...result,
        label: result.title,
        value: `${result.kind}-${result.id}`,
      }))}
      onInputValueChange={setQuery}
      onChange={handleChange}
      initialInputValue={initialInputValue}
      variant={variant}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default LocationField;
