import { useMemo } from 'react';
import { useLocation } from 'react-use';
import qs from 'qs';
import { get } from 'lodash';

export const useQueryParams = () => {
  const location = useLocation();
  return useMemo(() => qs.parse(get(location, 'search', '').substring(1)), [
    location,
  ]);
};
