import styled, { css } from 'styled-components';
import { media } from 'ui';
import React from 'react';
import { Field as RawField } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';

export const FormField = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
  props.variant === 'natural' &&
  css`
      margin-right: auto;
      margin-right: auto;
      width: auto;
      + * {
        margin-top: 0;
      }
    `}
`;

export const FormLabel = styled.label`
  text-align: left;
  color: ${({ theme }) => theme.textLighter};
  width: 100%;
  float: left;
`;

export const Input = styled.input`
  display: inline-block;
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing(0.75)};
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  color: inherit;
  outline: none;
  font-size: 20px;
  transition: border 150ms ease;

  &:hover{
    border: 1px solid rgba(0, 0, 0, 0.24);
  }

  &:focus{
    border: 1px solid ${({ theme }) => theme.primary};
  }



  ${props =>
  props.variant === 'natural' &&
  css`
      border: none;
      font-size: 24px;
      border-bottom: 2px dashed #999999;
      padding: 0;
      border-radius: 0;
    `}

  ${props =>
  props.hasBigFont &&
  css`
      font-size: 16px;

      ${media.tablet`
        font-size: 24px;
      `}
    `}

  ${props =>
  props.variant === 'search' &&
  css`
      height: 44px;
      padding: 0 ${({ theme }) => theme.spacing(0.5)};
      min-width: calc(100% - 56px);
      border: none;

      ::placeholder {
        font-weight: 600;
        white-space: nowrap;
        font-size: 14px;
        color: ${({ theme }) => theme.textLighter} !important;
        text-transform: uppercase;
        padding-left: ${({ theme }) => theme.spacing()};
      }

      &:hover,
      &:focus {
        border: 0;
      }

      ${media.desktop`
        width: auto;

      `}
    `}

    ${props =>
  props.variant === 'hotel' &&
  css`
        height: 44px;
        padding: 0 ${({ theme }) => theme.spacing()};
        min-width: calc(100% - 56px);
        border: none;

        ::placeholder {
          font-weight: 600;
          white-space: nowrap;
          font-size: 14px;
          color: ${({ theme }) => theme.textLighter} !important;
          text-transform: uppercase;
          padding-left: ${({ theme }) => theme.spacing()};
        }

        &:hover,
        &:focus {
          border: 0;
        }

        ${media.desktop`
          width: auto;
  
        `}
      `}

    
      ${props =>
  props.variant === 'footer' &&
  css`
          padding: ${({ theme }) => theme.spacing(0.5)};
          background-color: #fff;
          z-index: 1;
          font-size: 14px;
          height: 48px;
          width: 468px;
        `}

  ${props =>
  props.variant!==undefined && props.variant.indexOf('home')===0 &&
  css`
      height: 80px;
      text-transform: uppercase;
      font-size: 14px;
      min-width: calc(100% - 56px);
      padding: ${({ theme }) => theme.spacing()};
      width: 100%;

      ::placeholder {
        font-weight: 400;
        white-space: nowrap;
        color: ${({ theme }) => theme.textLight} !important;
        text-transform: uppercase;
        width: auto;
      }

      &:hover,
      &:focus {
        border: none;
      }
    `}
    
  ${props =>
  props.variant === 'homeSearch' &&
  css`
      padding:0;

      ::placeholder {
        text-transform: none;
        text-align:left;
      }
  `}

  ${props =>
  props.full &&
  css`
      height: 100%;
      border: none;
    `};

  ::placeholder {
    color: ${({ theme }) => theme.textLighter};
  }

  &:disabled {
    background-color: rgba(0, 40, 100, 0.04);
    cursor: not-allowed;
  }
`;

export const FormErrorMessage = styled.span`
  display: block;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ error, theme }) => (error ? theme.error : theme.warning)};
  font-size: 14px;
`;

export const Textarea = styled(TextareaAutosize)`
  padding: ${({ theme }) => theme.spacing(0.875)}
    ${({ theme }) => theme.spacing()};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 6px;
  color: ${({ theme }) => theme.text};
  font-size: 1em;
  font-weight: 400;
  outline: none;
  width: 100%;
  resize: none;

  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.darkSeparator};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLighter};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    color: inherit;
  }

  &:disabled {
    background-color: rgba(0, 40, 100, 0.04);
    cursor: not-allowed;
  }

  ${({ hasError }) =>
  hasError &&
  `
      &,
      &:active,
      &:focus {
        border-color: ${({ theme }) => theme.error};
      }
    `}
`;

export const FieldTitle = styled.div`
  color:#000;
  font-weight:bold;
  font-size: 12px;
  text-transform:uppercase;

  ${props =>
    props.variant === 'WidgetTitleLeft' &&
    css`
      margin-left: 8px;
  `}
  ${props =>
    props.variant === 'WidgetTitleRight' &&
      css`
      margin-left: 50px;
  `}
`

export const Field = props => {

  if (props.title) {
    return (
      <div>
        <FieldTitle>
          {props.title}
        </FieldTitle>
        <RawField {...props} />
      </div>
    );
  }
  return (
    <RawField {...props} />
  );
}

