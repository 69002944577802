const i18n = require('i18next').default;
const { initReactI18next } = require('react-i18next');
const HttpApi = require('i18next-http-backend').default;
const MergeBackend = require('./i18next-merge-backend').default;

i18n
  .use(MergeBackend)
  .use(initReactI18next)
  .init({
    lng: 'fr',
    fallbackLng: 'fr',
    ns: 'translation',
    defaultNS: 'translation',
    interpolation: { escapeValue: false },
    react: {
      useSuspense: false,
      bindI18n: 'languageChanged',
      bindI18nStore: 'add,remove',
    },
    backend: {
      backends: [HttpApi, HttpApi],
      backendOptions: [
        {
          loadPath: `/locales/{{lng}}.json`,
        },
        {
          loadPath:
            'https://champollion-api.tymate.com/i18n/ahFlfmNoYW1wb2xsaW9uLWFwaXIUCxIHUHJvamVjdBiAgICM5aGCCgw/{{lng}}',
        },
      ],
    },
  });

module.exports = i18n;
