import React, { useState, useEffect } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { Field } from 'formik';
import { FormLabel } from 'ui/forms';
import moment from 'moment';
import 'moment/locale/fr';
import 'react-dates/initialize';
import '../styles/dates.css';
import { DateRangePicker } from 'react-dates';
import { media } from 'ui';

moment.locale('fr');

const DatesPickerStyles = createGlobalStyle`
  .DateField .DateRangePicker {
    width: 100%;

    ${props =>
      props.variant === 'search' &&
      css`
        width: 100%;
      `}

    select {
      + select {
        margin-left: 0;
      }
    }
  }


  .DateField .DayPicker_transitionContainer, .DayPicker_transitionContainer_1, .DayPicker_transitionContainer__horizontal, .DayPicker_transitionContainer__horizontal_2 {
    position: absolute !important;
    top: 0;
    left: -30px;

    ${props =>
      Boolean(props.isInWidget) &&
      css`
        left: -5px;
      `}
  }

  .DateField .DayPicker_weekHeader, .DayPicker_weekHeader_1 {
    position: relative;
    top: 60px;
    left: -30px !important;

    ${props =>
      Boolean(props.isInWidget) &&
      css`
        left: -5px !important;
      `}
  }

  .DateField .DayPickerNavigation_rightButton__horizontalDefault {
    top: 20px;
    right: 62px;

    ${props =>
      Boolean(props.isInWidget) &&
      css`
        right: 22px;
      `}
  }
  .DateField .DayPickerNavigation_leftButton__horizontalDefault {
    top: 20px;
    left: 0;

    ${props =>
      Boolean(props.isInWidget) &&
      css`
        left: 20px;
      `}
  }

  .DateField .CalendarMonth, .CalendarMonth_1 {
    padding-left: 17px !important;
    padding-right: 16px !important;
    padding-bottom: 16px !important;
  }

  .DateField .DayPicker_weekHeaders {
    position: absolute;
  }

  .DateField .DayPicker_transitionContainer {
    border-radius: 0;
  }

  .DateField .DateRangePickerInput__withBorder.DateRangePickerInput__withBorder {  
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    height: 48px;

    ${props =>
      props.variant === 'home' &&
      css`
        height: 100%;
        border: none;
        border-radius: 0;
        height: 65px;

        ${({ isVertical }) =>
          !Boolean(isVertical) &&
          media.desktop`
          height: 80px;
        `}
      `};

      ${props =>
        props.variant === 'search' &&
        css`
          width: 100%;
          input {
            max-width: 100px;
          }

          ${media.desktop`
            input {
              max-width: 100px;
            }
          `}
        `};
  }

  .DateField .DateInput_input.DateInput_input {
    color: ${({ theme }) => theme.text} !important;
    height: 100%;
    font-weight: 400 !important;
    font-size: 16px !important;
    text-align: center !important;
    max-width: 120px !important;

    ${props =>
      props.full &&
      css`
        width: 100%;
      `}

    ::placeholder {
      font-weight: 500 !important;
      color: ${({ theme }) => theme.textLight} !important;
      text-transform: uppercase;
      font-size: 14px !important;
      text-align: center !important;
    }

`;

const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;

  ${props =>
    props.variant === 'natural' &&
    css`
      width: auto;
      height: 36px;
    `}

  ${props =>
    props.variant === 'search' &&
    css`
      margin-top: 0;
      height: 48px;
      width: auto;
    `}

  ${props =>
    props.variant === 'home' &&
    css`
      margin-top: 0;
      height: 80px;
    `}
`;

const DateInput = ({
  label,
  id,
  style,
  flex,
  className,
  variant,
  value,
  onChange,
  full,
  startDatePlaceholder,
  endDatePlaceholder,
  isVertical,
  isInWidget,
}) => {
  const [focusedInput, setFocusedInput] = useState();
  const [startDate, endDate] = value || [];

  useEffect(() => {
    onChange && onChange([startDate, endDate]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <Wrapper variant={variant} className={'DateField'}>
      <DatesPickerStyles
        isVertical={isVertical}
        variant={variant}
        full={full}
        isInWidget={isInWidget}
      />
      {label && <FormLabel>{label}</FormLabel>}
      <DateRangePicker
        startDate={Boolean(startDate) ? moment(startDate) : null}
        endDate={Boolean(endDate) ? moment(endDate) : null}
        verticalHeight={650}
        numberOfMonths={1}
        onDatesChange={({ startDate, endDate }) => {
          onChange([
            startDate ? startDate.format() : null,
            endDate ? endDate.format() : null,
          ]);
        }}
        startDatePlaceholderText={startDatePlaceholder}
        endDatePlaceholderText={endDatePlaceholder}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
      />
    </Wrapper>
  );
};

export const DateField = props => (
  <>
    <Field {...props}>
      {({ field, form: { setFieldValue } }) => (
        <DateInput
          {...props}
          {...field}
          onChange={value => {
            setFieldValue(field.name, value);
          }}
        />
      )}
    </Field>
  </>
);

export default DateInput;
