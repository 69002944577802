import React, { useContext } from 'react';
import LogoPartFeelin from '../assets/images/logoAnimation.gif';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { media } from '../ui';
import PageContext from '../contexts/page';

const Logo = styled(Link)`
  cursor: pointer;
  outline: none;
  text-decoration: none;
  color: #000;
`;


const LogoImg1 = styled.img`
  height: 25px;
  display: inline;

  ${media.desktop`
    height: 40px;
  `}
`;


export default function FooterLogo() {
  const { language } = useContext(PageContext);

  return (
    <Logo to={language === 'fr' ? '/' : '/'+language}>
      <LogoImg1 src={LogoPartFeelin} alt="Feelingo-logo"/>
    </Logo>
  )
}
